<template>
    <v-expansion-panel class="version-history">
        <v-expansion-panel-content
            v-for="version in versionHistory"
            :key="version.versionNumber"
            class="version-history__accordion-item"
        >
            <div
                slot="header"
                :class="[
                'version-history__accordion-header',
                {
                    'version-history__accordion-header--new': checkIfNew(
                        version.versionNumber
                    ),
                },
                {
                    'version-history__accordion-header--coming-soon': checkIfUpcoming(
                        version.versionNumber
                    ),
                },
            ]"
            >
                v {{ version.versionNumber }}
            </div>
            <v-card>
                <v-card-text
                    v-html="version.versionDetails"
                    class="wysiwyg"
                ></v-card-text>
            </v-card>
        </v-expansion-panel-content>

        <span class="version-history__contact">If you believe you're missing a feature listed here, or would like to
            discuss a feature please raise a ticket in Freshdesk using the help button
            in the bottom left of the window.</span>
    </v-expansion-panel>
</template>

<script>
import { version } from "../../../package.json";

export default {
    name: "VersionHistory",

    data: () => ({
        currentVersion: version,
        newReleases: "",
        versionHistory: [
        {
                versionNumber: "1.25.14",
                versionDetails: `
            <p>Fixes</p>
            <ul>
                <li>Fixed user edit</li>
                <li>Fixed users search</li>
            </ul>
            `,
            },
            {
                versionNumber: "1.25.13",
                versionDetails: `
            <p>Fixes</p>
            <ul>
                <li>Fix missing subtitle module</li>
            </ul>
            `,
            },
            {
                versionNumber: "1.25.12",
                versionDetails: `
            <ul>
                <li>Add missing users properties</li>
                <li>Add new efl broadcaster option</li>
            </ul>
            `,
            },
            {
                versionNumber: "1.25.6",
                versionDetails: `
            <p>Improvements</p>
            <ul>
                <li>Additional User Management options</li>
            </ul>
            `,
            },
            {
                versionNumber: "1.25.4",
                versionDetails: `
            <p>Improvements</p>
            <ul>
                <li>Player deletion option</li>
                <li>"Third Shirt" option for player profile</li>
                <li>Extend advertising 2.0 capabilities</li>
            </ul>
            `,
            },
            {
                versionNumber: "1.25.0",
                versionDetails: `
            <p>Features</p>
            <ul>
                <li>New Blog feature</li>
            </ul>
            `,
            },
            {
                versionNumber: "1.24.0",
                versionDetails: `
            <p>Features</p>
            <ul>
                <li>New Documents feature</li>
                <li>URL navigation</li>
            </ul>
            <p>Technical changes</p>
            <ul>
                <li>Typescript support</li>
            </ul>
            `,
            },
            {
                versionNumber: "1.23.0",
                versionDetails: `
          <p>Features</p>
          <ul>
            <li>Additional advertising functionality</li>
            <li>New supporter's clubs functionality</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.18",
                versionDetails: `
          <p>Improvements</p>
          <ul>
            <li>Streamline video filtering and clipping tool improvements</li>
            <li>Enable video preview for Streamline live videos</li>
            <li>Additional Social Media support: Threads and X</li>
          </ul>
          <p>Fixes</p>
          <ul>
            <li>Fixes bugs in incomplete widgets</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.17",
                versionDetails: `
          <p>Improvement</p>
          <ul>
            <li>New Streamline options</li>
            <li>New calendar options</li>
          </ul>
          <p>Fixes</p>
          <ul>
            <li>Various minor fixes</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.16",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Removed Matchday Takeover V2 [beta] fields for custom fixtures in the fixture editor as these are not currently supported.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.15",
                versionDetails: `
          <p>Improvement</p>
          <ul>
            <li>Media Library upload limit increased.</li>
          </ul>
          <p>Change</p>
          <ul>
            <li>New TinyMCE API key added.</li>
            <li>Live stream checkbox added to club tv widget for in app news article streaming.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.14",
                versionDetails: `
          <p>Judo</p>
          <ul>
            <li>Rover functionality moved to Judo.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.13",
                versionDetails: `
          <p>Feature</p>
          <ul>
            <li>Adds ability to override opta venue data in the fixture admin.</li>
          </ul>
          <p>Fix</p>
          <ul>
            <li>Adds a way to handle video pages that do not follow the standard paths</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.12",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Matchday takeover v2 fix, allowing cancellation of takeover timings being set in the modal</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.11",
                versionDetails: `
          <p>Update</p>
          <ul>
            <li>Streamline calendar additional options and UI improvement</li>
            <li>Adds an app config for match centre backgrounds in the app.</li>
          </ul>
          <p>Fix</p>
          <ul>
            <li>Matchday takeover v2 fix, allowing cancelation of timings properly</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.10",
                versionDetails: `
          <p>Update</p>
          <ul>
            <li>Config changes</li>
            <li>Added a return button inside calendar items</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.9",
                versionDetails: `
          <p>Feature</p>
          <ul>
            <li>New calendar content widget</li>
            <li>Added filters to calendar functionality</li>
            <li>Improved calendar UI</li>
            <li>Added calendar options to fixture UI</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.8",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Fixed an issue where users were unable to edit custom tables.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.7",
                versionDetails: `
          <p>Config</p>
          <ul>
            <li>Combined Custom Tags and Default Settings JS files.</li>
            <li>Homepage field changes on request.</li>
            <li>Internal test environment files added.</li>
          </ul>
          <p>Streamline</p>
          <ul>
            <li>Added logic for the availability of Streamline Widgets in App.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.6",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Updated configs to fix missing homepage takeovers for certain clubs.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.5",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Streamline - Removed email address from creation of entitlement.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.4",
                versionDetails: `
          <p>Improvement</p>
          <ul>
            <li>Streamline - Added a config to be able to remove the association between entitlements and email addresses.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.3",
                versionDetails: `
          <p>Fixes / Improvements</p>
          <ul>
            <li>Config Updates.</li>
            <li>Videos V2 - Added Filtered Type & Item limit to Streamplay list widget</li>
            <li>Videos V2 - Fixed issue where benefits could not be created for new memberships</li>
            <li>Videos V2 - Fixed issue where imageKey was not created for the Promo widget</li>
            <li>Videos V2 - Fixed issue where carousel category was not displaying after being selected</li>
            <li>Matchday Takeover - Set up for new Matchday Takeover Component that is in active development. Clubs involved in the beta will see updates to their fixture edit pages and dashboard.</li>
            <li>Pages - Hides Analytics and content tabs for Internal Link Page types.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.2",
                versionDetails: `
          <p>Fixes / Improvements</p>
          <ul>
            <li>Various fixes for the video content manager for StreamAMG clubs.</li>
            <li>New video manager CMS user type for Video Integration V2 management.</li>
            <li>Player profile view now defaults to v3 for clubs that still need to populate these profiles.</li>
            <li>Website page styles / settings moved higher up Player Management v3 screen</li>
            <li>Small UI update to the featured news block to reflect the News Stacker improvements in the pipeline.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.1",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Restricts the Homepage takeover selector to the first team only to reflect the current implementation.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.22.0",
                versionDetails: `
          <p>Release 1.22 contains the tools needed to manage the V2 version of Gamechanger video integration and also the Streamline platform extension (Urban Zoo built OTT service). The team are working on preparing the frontend of the platform for this release.  Over the course of the next few weeks you will be notified when there is a preview version of this feature to review and at this point the video tools will be enabled in your CMS for training and setting up the final video structure of your site.</p>
          <p>Beta users of streamline will retain access to this area of the platform, and current videos V2 clients may recieve full CMS access early.</p>
        `,
            },
            // {
            //   versionNumber: 'beta-streamline-0.2',
            //   versionDetails: `
            //     <p>Improvements / Feature / Fixes</p>
            //     <ul>
            //       <li>Statement Descriptors now have a max length of 22 characters
            //       <li>Live Streams will now appear at the top of the list if active
            //       <li>Packages style editing will only show relevant image slots based on style selected
            //       <li>Improved descriptions for some fields
            //       <li>Video Clipping Functionality.</li>
            //       <li>Fixes an issue where new memberships cannot be added</li>
            //     </ul>
            //   `
            // },
            // {
            //   versionNumber: 'beta-streamline-0.1',
            //   versionDetails: `
            //     <p>Update / Improvement</p>
            //     <ul>
            //       <li>Beta release for the UZ Streamline product.</li>
            //     </ul>
            //   `
            // },
            {
                versionNumber: "1.21.12",
                versionDetails: `
          <p>Update</p>
          <ul>
            <li>Config Updates for player profile V3.</li>
          </ul>
          `,
            },
            {
                versionNumber: "1.21.11",
                versionDetails: `
          <p>Update / Improvement</p>
          <ul>
            <li>Added pagination to search and directories within the media library.</li>
            <li>Functionality to restrict ability to delete key placeholders.</li>
            <li>Config Changes.</li>
          </ul>
          `,
            },
            {
                versionNumber: "1.21.10",
                versionDetails: `
          <p>Update / Improvement</p>
          <ul>
            <li>Updated config variables for some clubs.</li>
            <li>Added improved match picker component to homepage takeover.</li>
            <li>Added pagination to forms list.</li>
          </ul>
          `,
            },
            {
                versionNumber: "1.21.9",
                versionDetails: `
          <p>Update</p>
          <ul>
            <li>Updated season variables for fixture release.</li>
          </ul>
          `,
            },
            {
                versionNumber: "1.21.8",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Increased number of form responses as some clubs had hit the maximum number.</li>
          </ul>
          `,
            },
            {
                versionNumber: "1.21.7",
                versionDetails: `
          <p>Configuration changes</p>
          <ul>
            <li>Updated config options.</li>
          </ul>
          `,
            },
            {
                versionNumber: "1.21.6",
                versionDetails: `
          <p>Feature / Update</p>
          <ul>
            <li>Global alert system for notifications, issues, and information to all Gamechanger clients.</li>
            <li>Config Changes</li>
          </ul>
          `,
            },
            {
                versionNumber: "1.21.5",
                versionDetails: `
          <p>Updates / Fixes:</p>
          <ul>
            <li>Fixes an issue where the data wasn't loading properly in the "Add Single Fixture" content widget.</li>
            <li>Config Changes</li>
          </ul>
          `,
            },
            {
                versionNumber: "1.21.4",
                versionDetails: `
          <p>Updates / Fixes:</p>
          <ul>
            <li>New 'Team Management' landing page to navigate to Fixtures / Squads / Team Crests.</li>
            <li>Team Crest Manager section to allow different club crests to be used in different website slots.  Available in web v1.21.15 and above</li>
            <li>Fixes an issue where the featured news block did not update properly when making changes</li>
            <li>Config Changes</li>
          </ul>
          `,
            },
            {
                versionNumber: "1.21.3",
                versionDetails: `
          <p>Updates:</p>
          <ul>
            <li>Added a default layout configuration option for player profile v3. (Requires managed admin release).</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.21.2",
                versionDetails: `
          <p>Updates / Fixes:</p>
          <ul>
            <li>Made Player Profile V3 management page available to prepare for website updates.</li>
            <li>Added an additional check for empty news titles on save.</li>
            <li>Triggered save status message when adding images in the Website Settings.</li>
            <li>Config changes.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.21.1",
                versionDetails: `
          <p>Fixes:</p>
          <ul>
            <li>Changed player profile v3 UI</li>
            <li>Updated web settings help text</li>
            <li>Added larger text area for specific build paths</li>
            <li>Updated help text for listing images.  Applies to web version 1.21 and above.</li>
            <li>Config changes</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.21.0",
                versionDetails: `
          <p>Major Version Update:</p>
          <ul>
            <li>Detailed Fathom Analytics on news & pages</li>
            <li>Global setting for Header Styles (Requires managed site update)</li>
            <li>New Player Profile (Requires managed site update)</li>
            <li>News page pagination</li>
            <li>Config changes</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.11",
                versionDetails: `
          <p>Behind the scenes updates for upcoming improvements to:</p>
          <ul>
            <li>Advertising Analytics</li>
            <li>Speech tools</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.10",
                versionDetails: `
          <p>Config</p>
          <ul>
            <li>Homepage config update.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.9",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Fixes an issue where article, news and match previews were unavailable for some clients.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.8",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Fixes an issue where news categories were unavailable in news widgets.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.7",
                versionDetails: `
          <p>Improvement</p>
          <ul>
            <li>Defaults article hero image style to the 'Deep' option.</li>
            <li>Additional Fathom Site Validation.</li>
            <l1>Adds login lock to Club TV (Stream) embeds.</l1>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.6",
                versionDetails: `
          <p>Improvements</p>
          <ul>
            <li>Basic website stats are now pulled into the dashboard. These are powered by Fathom.</li>
            <li>We can now have a default child friendly advertising value attached to a category. This will help set default values on news articles and power more child friendly pages in the website. If you require categories set to this please request in freshdesk.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.5",
                versionDetails: `
          <p>Improvement</p>
          <ul>
            <li>The ability to copy content pages has been reinstated.</li>
            <li>The ability to copy news posts has been added.</li>
            <li>Adds the option to uplad an image to the custom table editor.  Requires a configuration update.  If required soon please request via freshdesk.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.4",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Fixes issue where the custom table modal stopped custom table content entry.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.3",
                versionDetails: `
          <p>Improvements / Fixes</p>
          <ul>
            <li>Adds the ability to automatically unpublish news posts at a set date / time.</li>
            <li>Adds the ability to add custom tables for teams via the Squads tab.  Requires a configuration update.  If required please request via freshdesk.</li>
            <li>Fixes an issue with news stories not closing first time when "Save and Close" is selected.</li>
            <li>Fixes minor visual issues for pdf display in the media library.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.1",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Fixes an issue with alternative language post slugs not being properly created.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.20.0",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>News Post Slugs are now editable.</li>
            <li>Player Retail Kit options available are Home / away / Goalkeeper / Custom.  Custom is set per player if required and standard kits are managed via the Website Management tab.</li>
            <li>Fixed a broken opta logo</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.43",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Reverted post slug change.  Issue with editors unable to update post slug.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.42",
                versionDetails: `
          <p>Improvement / Fix</p>
          <ul>
            <li>Added recommended image size information to the player management page.</li>
            <li>Added slots for the player kit assets to the Web Settings.  This is not in use on the website yet but assets can be uploaded in preperation.</li>
            <li>Bug fix for duplicated news post slugs causing errors in navigation / build.</li>
            <li>Added a loading state to the main news listing.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.41",
                versionDetails: `
          <p>Fix</p>
          <ul>
            <li>Custom player positions were allowing string entry when it should be a dropdown of available positions. This was updated incorrectly during a layout improvement. This has now been changed back</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.40",
                versionDetails: `
          <p>Fixes/Improvements</p>
          <ul>
            <li>Removed a duplicate Match Stats widget button</li>
            <li>Added a html editor to player biographies. Please use this with caution</li>
            <li>Added a help button to the player biographies wywiwyg editor</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.39",
                versionDetails: `
          <p>Fixes/Improvements</p>
          <ul>
            <li>Fixed an issue where users on pages couldn't see some fields</li>
            <li>Added new inline help document links</li>
            <li>Beta: Added admin for "Where to stream?" fixture info in preparation for new features. This will not be available to all users for a while yet</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.38",
                versionDetails: `
          <p>Fixes/Improvements</p>
          <ul>
            <li>Added internal link option to pages</li>
            <li>Added some preview links for matches</li>
            <li>Improved signposting</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.37",
                versionDetails: `
          <p>Fixes/Improvements</p>
          <ul>
            <li>Fixed some issues on the SSO user management screen</li>
            <li>Added additional settings to the Rewards Manager screen</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.36",
                versionDetails: `
          <p>Fixtures list fix</p>
          <ul>
            <li>Fixes a breaking issue where fixtures were not being displayed on the page</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.35",
                versionDetails: `
          <p>Fixtures list change</p>
          <ul>
            <li>We have merged custom and imported fixtures into a single list</li>
            <li>Any widgets that require a match selection have been updated</li>
            <li>You should now be able to use custom fixtures in all widgets</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.34",
                versionDetails: `
          <p>Fixes/Improvements</p>
          <ul>
            <li>Fixed incorrect error messages in SSO user deletion</li>
            <li>Fixed incorrect user journey in SSO user deletion</li>
            <li>Improved fixtures match kick off panels UI</li>
            <li>Fixed an issue with UTC times not displaying correctly in fixtures admin</li>
            <li>Allow selection of custom fixtures in news tags</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.33",
                versionDetails: `

          <p>Bugfixes</p>
          <ul>
            <li>Fixed an issue where App Manager / Social Links Manager was unable to add or edit icon images for the links.</li>
            <li>Added player join date back into admin</li>
            <li>General fixes</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.32",
                versionDetails: `
          <p>Improvement</p>
          <ul>
            <li>We've added an App specific ecal field into the App Management config. This requires an app update before it's visible.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.31",
                versionDetails: `
          <p>Bugfix</p>
          <ul>
            <li>Fixed an issue where new Gamechanger SSO users couldn't be created in the admin.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.30",
                versionDetails: `
          <p>CMS user updates</p>
          <ul>
            <li>Standardising new admin users emails as it was possible for them to be uppercased which made them unique.</li>
            <li>Improved admin user management panel to separate out active and inactive users.</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.29",
                versionDetails: `
          <p>Player admin Fixes</p>
          <ul>
            <li>During the update to the new player admin layout a couple of issues with data saving arose. The following have been fixed.</li>
            <li>Player numbers can now be customised again</li>
            <li>Advanced player heros can be set active again</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.28",
                versionDetails: `
          <p>Updates/Fixes</p>
          <ul>
            <li>Fixed an issue with users not being able to access squads when not an admin</li>
            <li>Added a news carousel widget for embedding on pages [Requires web update]</li>
            <li>Fixed a bug on the section sponsors which caused incorrect data when using it quickly</li>
            <li>Fixed issue with player enabled dropdown not populating on load</li>
            <li>Improved some help text on player admin screens</li>
            <li>Gamechanger Rewards management page [New app module]</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.27",
                versionDetails: `
          <p>Minor updates</p>
          <ul>
            <li>Updated the player admin screen layout</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.26",
                versionDetails: `
          <p>Minor updates</p>
          <ul>
            <li>Some clients may have new app menu screens for the Gamechanger May app release enabled</li>
            <li>Behind the scenes build improvement for featured news</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.25",
                versionDetails: `
          <p>Bug fixes</p>
          <ul>
            <li>Fixes to social media menu screen</li>
            <li>[Alpha] New advertising module testing</li>
          </ul>
         `,
            },
            {
                versionNumber: "1.19.24",
                versionDetails: `
          <p>Improvements</p>
            <ul>
              <li>Added schema validation warning to pages</li>
              <li>Improved the App Ticketing and App E-Commerce admin screens</li>
              <li>Introduced an App Social Media admin Screen (requires App update)</li>
            </ul>
         `,
            },
            {
                versionNumber: "1.19.23",
                versionDetails: `
          <p>Improvement</p>
          <ul>
            <li>Added a new login portal field into the web settings manager</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.22",
                versionDetails: `
          <p>Bug fix</p>
          <ul>
            <li>An issue that prevented the last matches from appearing in match lists has been resolved. This only affected seasons with over 50 matches.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.21",
                versionDetails: `
          <p>Bug fixes / improvements</p>
          <ul>
            <li>The media library details now includes the src url for reference</li>
            <li>When closing the media library image details in the popup version it inadvertently closed the whole media library. This doesn't happen anymore</li>
            <li>The media library had a bit of a weird fixed height which led to a inception type scrollbar situation. The media library is now a bit more flexible with it's height. This may be worked on further</li>
            <li>Gamechanger SSO email field is now read only with better description text</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.20",
                versionDetails: `
          <p>Bug fixes / improvements</p>
          <ul>
            <li>You now cannot set a publish time against draft news posts. This UX improvement should make it clearer that draft news posts do not automatically swap being published if they were scheduled</li>
            <li>Fixed an issue with users not being able to access Overlay Promos even with the correct permission</li>
            <li>Fixed button text in the SSO user management section</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.19",
                versionDetails: `
          <p>Bug fix / improvements</p>
          <ul>
            <li>Changed the UI around news post scheduling to make it clearer what you are saving, and display initial times in your local timezone.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.18",
                versionDetails: `
          <p>Updates to Rover</p>
          <ul>
            <li>Added access levels to Rover items. Make them free to everyone, require login, or require premium. [requires app update]</li>
            <li>Added expiry date for Rover items. Automatically remove rover items on expiry date. These should still be deleted when you get a chance so that your admin doesn't become clogged up.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.17",
                versionDetails: `
          <p>[Alpha update] Added regions filter for organisations module</p>
        `,
            },
            {
                versionNumber: "1.19.16",
                versionDetails: `
          <p>[Requires web update] Metadata improvements</p>
          <ul>
            <li>There are new meta data configs in the web settings admin</li>
            <li>You can insert a global value before and/or after the page title. This will be applied across all pages</li>
            <li>There are also new values for the news page title and news page description</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.14",
                versionDetails: `
          <ul>
            <li>[Requires app update] Additional App Settings for notifications.</li>
            <li>[Requires app update] App ticketing screen now has an image slot.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.13",
                versionDetails: `
          <ul>
            <li>[Requires app update] Added App Settings for controlling Notification topic descriptions.</li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.12",
                versionDetails: `
          <ul>
            <li>[Requires web update] Added new Football Match Stats widget for website news posts. <i>This takes a snapshot of data when you create the widget.</i></li>
            <li>[Requires web update] Added new Football Lineups widget for website news posts. <i>This takes a snapshot of data when you create the widget.</i></li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.11",
                versionDetails: `
          <p>The Media Library UI has had a large update. Some of the improvements are:</p>
          <ul>
            <li>Rebuilt the UI from scratch to speed it up and squash some bugs</li>
            <li>Multi file uploads with drag and drop</li>
            <li>Better folder navigation</li>
            <li>Download source images</li>
            <li>Clipboard which allows you to select an image before adding it to a post</li>
            <li>There is now support for gifs</li>
          </ul>
          <p>Tags have been removed for now as they were not in use. There will be a further update to tagging</p>
          <p>Image upload size limits are still not correct and a new method is being worked on.</p>
        `,
            },
            {
                versionNumber: "1.19.10",
                versionDetails: `
          <p>Small update</p>
          <ul>
            <li>Turned on the simple tips and advice module</p>
            <li>[Requires web update] Added additional settings in the Website settings manager. You can now also edit:
              <ul>
                <li>Video Placeholder Image</li>
                <li>Copyright notice in footer</li>
              </ul>
            </li>
          </ul>
        `,
            },
            {
                versionNumber: "1.19.9",
                versionDetails: `
          <p>Welcome to the new release notes system. This is an easy way to keep all users up to date with new features, bug fixes, and upcoming releases to the Gamechanger Admin.</p>
          <p>If you have any questions regarding the modules or notes, please raise them within Freshdesk</p>
          <ul>
            <li>Release notes module</li>
            <li>Opta Insights module</li>
            <li>[Requires web update] Ability to manage loaned out players from the CMS</li>
            <li>[Requires web update] Manage lots of new Website settings</li>
            <li>[Requires web update] Article Card Style management - This accompanies a website refresh to these components</li>
            <li>[Experimental] - Notification system for if someone is editing a news post and you try to edit it</li>
            <li>UI improvements to News and Pages configuration tabs</li>
          </ul>
        `,
            },
        ],
    }),

    mounted() {
        this.checkNewReleases();
    },

    methods: {
        checkNewReleases() {
            var viewedReleases = document.cookie.replace(
                /(?:(?:^|.*;\s*)_gc_releases\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
            );
            var cookieName = "_gc_releases=";
            var cookieExpiry = "; expires=Thu, 31 Dec 2099 00:00:00 UTC;";

            for (let i = 0; i < this.versionHistory.length; i++) {
                if (this.checkIfUpcoming(this.versionHistory[i].versionNumber)) {
                    if (!viewedReleases.includes(this.versionHistory[i].versionNumber)) {
                        this.newReleases +=
                            this.versionHistory[i].versionNumber + "(upcoming), ";
                    }
                } else {
                    if (
                        viewedReleases.includes(
                            this.versionHistory[i].versionNumber + "(upcoming)"
                        ) &&
                        !viewedReleases.includes(this.versionHistory[i].versionNumber + ",")
                    ) {
                        this.newReleases += this.versionHistory[i].versionNumber + ", ";
                    } else if (
                        !viewedReleases.includes(this.versionHistory[i].versionNumber)
                    ) {
                        if (i < this.versionHistory.length - 1) {
                            this.newReleases += this.versionHistory[i].versionNumber + ", ";
                        } else {
                            this.newReleases += this.versionHistory[i].versionNumber;
                        }
                    }
                }
            }

            if (this.newReleases) {
                document.cookie =
                    cookieName + this.newReleases + viewedReleases + cookieExpiry;
                this.$emit("newVersion");
            }
        },

        checkIfNew(version) {
            if (this.newReleases.includes(version)) {
                return true;
            }
        },

        checkIfUpcoming(version) {
            var currentVersionSplit = this.currentVersion.split(".");
            var versionSplit = version.split(".");

            if (Number(currentVersionSplit[0]) < Number(versionSplit[0])) {
                return true;
            } else if (
                Number(currentVersionSplit[0]) == Number(versionSplit[0]) &&
                Number(currentVersionSplit[1]) < Number(versionSplit[1])
            ) {
                return true;
            } else if (
                Number(currentVersionSplit[0]) == Number(versionSplit[0]) &&
                Number(currentVersionSplit[1]) == Number(versionSplit[1]) &&
                Number(currentVersionSplit[2]) < Number(versionSplit[2])
            ) {
                return true;
            }
        },
    },
};
</script>

<style
    scoped
    lang="scss"
>
.version-history {
    margin-top: 8px;
    padding: 0 16px 24px;
    box-shadow: none;

    &__accordion-item {
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
        border-radius: 4px;
        margin: 8px 0 0;
    }

    &__accordion-header {
        font-weight: 500;
        display: flex;
        align-items: center;

        &:after {
            margin-left: 4px;
            padding: 1px 3px;
            font-weight: 700;
            font-size: 7px;
            color: white;
            border-radius: 2px;
        }

        &--new:after {
            content: "NEW";
            background-color: #5fad41;
        }

        &--coming-soon:after {
            content: "COMING SOON";
            background-color: #67247c;
        }
    }

    &__contact {
        margin-top: 24px;
        font-size: 12px;
    }

    /deep/ .wysiwyg {
        p {
            margin-bottom: 1.5em;
            font-size: inherit;
        }

        ul {
            margin: 1em 0;
            padding: 0 0 0 1em;
            list-style: none;
            counter-reset: li;

            li {
                margin: 0 0 1em;
                font-size: 1em;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    background-color: #212121;
                    border-radius: 50%;
                    top: 0.5em;
                    left: -1em;
                }
            }
        }
    }
}

.v-expansion-panel__container--active {
    margin: 16px 0 8px;
}

.v-card {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
</style>
