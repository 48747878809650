<template>
   <v-dialog
    v-model="dialog"
    fullscreen
  >
    <template v-slot:activator="{ on }">
      <v-btn
        key="insights"
        color="accent"
        dark
        fab
        small
        class="insights-button"
        v-on="on"
      >
        <v-icon>insights</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="headline grey lighten-2"
        primary-title
      >
        Insights

        <p class="powered-by">Powered by
          <img src="@/assets/partner-assets/opta.png" width="35px" alt="Opta" />
        </p>
      </v-card-title>

      <v-card-text>
        <div class="insight-selector">
          <match-picker @updated="fetchFixture($event)"/>

          <v-select
            v-model="insightSelect"
            item-text='name'
            item-value='value'
            :items="[
              { name: 'Player Match Stats', value: 'players' },
              { name: 'Team Match Stats', value: 'team' }
            ]"
            solo
            flat
            required/>
        </div>

        <template v-if="matchData && !statsLoading">

          <table v-if="insightSelect === 'team'" class="match-stats">
            <thead>
              <th>Stat</th>
              <th class="center">{{ matchData.teamData[0].shortTeamName }}</th>
              <th class="center">{{ matchData.teamData[1].shortTeamName }}</th>
            </thead>
            <tbody>
              <tr v-for="stat in teamStats" :key="stat.key">
                <td class="cell-label">{{ stat.label }} <span>{{ stat.key }}</span></td>
                <td class="center">{{ matchData.teamData[0].teamStats[stat.key] || 0 }}</td>
                <td class="center">{{ matchData.teamData[1].teamStats[stat.key] || 0 }}</td>
              </tr>
            </tbody>
          </table>

          <v-data-table
            v-else-if="insightSelect === 'players'"
            :headers="playerHeaders"
            :items="players"
            v-model="selectedPlayers"
            :pagination.sync="pagination"
            item-key="surname"
            select-all
            class="elevation-2"
          >
            <template v-slot:headers="props">
              <tr>
                <th class="player-club">
                  <v-checkbox
                    :input-value="props.all"
                    :indeterminate="props.indeterminate"
                    primary
                    hide-details
                    @click.stop="toggleAll"
                  ></v-checkbox>
                </th>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                  @click="changeSort(header.value, header.key )"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                  <span class="opta-stat-label">{{ header.key }}</span>
                </th>
              </tr>
            </template>
            <template v-slot:items="props">
              <td class="player-club" :style="`border-left-color:${showClientColor(props.item.isClient)}`">
                <v-checkbox
                  v-model="props.selected"
                  primary
                  hide-details
                ></v-checkbox>
              </td>
              <template v-for="item in playerHeaders">
                <td v-if="item.value === 'shirtNumber'" :key="item.value" class="player-label-cell">
                  {{ props.item.shirtNumber }}
                </td>
                <td v-else-if="item.value === 'surname'" :key="item.value" class="player-label-cell">
                  {{ props.item.firstName }} {{ props.item.surname }}
                </td>
                <td v-else :key="item.value" class="player-stat-cell">
                  {{ props.item.playerStats[item.key] }}
                </td>
              </template>
            </template>
          </v-data-table>

        </template>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          flat
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"
import moment from "moment"
import MatchPicker from "@/components/Library/MatchPicker"

export default {
  name: "EditorialStatsContainer",

  components: {
    MatchPicker
  },

  watch: {
    selectedPlayers: function(val) {
      this.$store.commit("savePlayerInsights", {
        players: val,
        sortedBy: this.sortKey
      });
    }
  },

  data:() => ({
    dialog: false,
    matchData: null,
    statsLoading: false,
    pagination: {
      sortBy: "surname"
    },
    sortKey: "surname",
    insightSelect: "players", // team, players
    teamStats: [
      {
        label: "Goals",
        key: "goals"
      },
      {
        label: "Possession %",
        key: "possession_percentage"
      },
      {
        label: "Total Shots",
        key: "total_scoring_att"
      },
      {
        label: "Shots on target",
        key: "ontarget_scoring_att"
      },
      {
        label: "Fouls",
        key: "fk_foul_lost"
      },
      {
        label: "Corners won",
        key: "won_corners"
      },
      {
        label: "Yellow cards",
        key: "total_yel_card"
      },
      {
        label: "Red cards",
        key: "total_red_card"
      },
      {
        label: "Accurate passes",
        key: "accurate_pass"
      },
      {
        label: "Touches",
        key: "touches"
      },
      {
        label: "Dribbles past",
        key: "won_contest"
      },
      {
        label: "Interceptions won",
        key: "interception_won"
      },
      {
        label: "Possession won Def 3rd",
        key: "poss_won_def_3rd"
      },
      {
        label: "Possession won Mid 3rd",
        key: "poss_won_mid_3rd"
      },
      {
        label: "Possession won Att 3rd",
        key: "poss_won_att_3rd"
      },
      {
        label: "Offsides",
        key: "total_offside"
      }
    ],
    selectedPlayers: [],
    playerHeaders: [
      {
        text: "Shirt",
        align: "left",
        sortable: false,
        value: "shirtNumber"
      },
      {
        text: "Player",
        align: "left",
        value: "surname"
      },
      {
        text: "Touches",
        value: "playerStats.touches",
        key: "touches",
      },
      {
        text: "Passes",
        value: "playerStats.total_pass",
        key: "total_pass",
      },
      {
        text: "Goals",
        value: "playerStats.goals",
        key: "goals",
      },
      {
        text: "Assists",
        value: "playerStats.goal_assist",
        key: "goal_assist",
      },
      {
        text: "Free Kick Shots",
        value: "playerStats.att_freekick_total",
        key: "att_freekick_total"
      },
      {
        text: "Free Kick Goals",
        value: "playerStats.att_freekick_goal",
        key: "att_freekick_goal"
      },
      {
        text: "Shots total",
        value: "playerStats.total_scoring_att",
        key: "total_scoring_att",
      },
      {
        text: "Shots (on target)",
        value: "playerStats.ontarget_scoring_att",
        key: "ontarget_scoring_att",
      },
      {
        text: "Own goals",
        value: "playerStats.own_goals",
        key: "own_goals",
      },
      {
        text: "Saves",
        value: "playerStats.saves",
        key: "saves",
      },
      {
        text: "Clean sheet",
        value: "playerStats.clean_sheet",
        key: "clean_sheet",
      },
      {
        text: "Dribbles past",
        value: "playerStats.won_contest",
        key: "won_contest",
      },
      {
        text: "Duels won",
        value: "playerStats.duel_won",
        key: "duel_won",
      },
      {
        text: "Possession won def",
        value: "playerStats.poss_won_def_3rd",
        key: "poss_won_def_3rd",
      },
      {
        text: "Possession won mid",
        value: "playerStats.poss_won_mid_3rd",
        key: "poss_won_mid_3rd",
      },
      {
        text: "Possession won att",
        value: "playerStats.poss_won_att_3rd",
        key: "poss_won_att_3rd",
      },
      {
        text: "Fouls",
        value: "playerStats.fouls",
        key: "fouls",
      },
      {
        text: "Was fouled",
        value: "playerStats.was_fouled",
        key: "was_fouled",
      }
    ],
    players: []
  }),

  mounted() {
    // this.fetchFixture()
  },

  methods: { 
    toggleAll () {
      if (this.selectedPlayers.length) this.selectedPlayers = []
      else this.selectedPlayers = this.players.slice()
    },
  
    changeSort (column, sortKey) {
      this.sortKey = sortKey
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    showClientColor(isClient) {
      if (isClient) {
        return `#${process.env.VUE_APP_BRAND_COLOR}`
      } else {
        return "#fff"
      }
    },

    fetchFixture(matchData) {
      // matchData = {
      //   seasonID: '2020',
      //   teamID: 't49',
      //   matchID: 'g2128297'
      // }
      this.statsLoading = true
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_WEBAPI}/fixtures/opta/getsingle?seasonID=${matchData.seasonID}&teamID=${matchData.teamID}&matchID=${matchData.matchID}`, { "headers": { "Authorization": jwt }})
        // .get(`https://webapi.gc.sheffieldunited.co.uk/v1/fixtures/opta/getsingle?seasonID=2020&teamID=t49&matchID=g2128297`, { 'headers': { 'Authorization': jwt }})
        .then(response => {
          console.log("Fetch match response: ", response);

          this.matchData = response.data.body

          const playersHome = this.matchData.teamData[0].teamPlayers.map((player) => {
            player.team = "home"

            if (matchData.teamID === this.matchData.teamData[0].teamID) {
              player.isClient = true
            } else {
              player.isClient = false
            }

            return player
          })

          const playersAway = this.matchData.teamData[1].teamPlayers.map((player) => {
            player.team = "away"

            if (matchData.teamID === this.matchData.teamData[1].teamID) {
              player.isClient = true
            } else {
              player.isClient = false
            }

            return player
          })

          this.players = playersHome.concat(playersAway)

          this.statsLoading = false

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving match: ", e);
          this.$store.commit("completeLoading");
          this.statsLoading = false
        });
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .insights-button {
    position: fixed;
    top: 6px;
    right: 6px;
    z-index: 10000;
  }

  .match-stats {
    padding: 0;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th {
      padding: 12px;
      color: rgba(0,0,0,0.54);
      font-weight: 500;
      font-size: 12px;
      text-align: left;
      border-bottom: 1px solid #ebebeb;

      &.center {
        text-align: center;
      }
    }

    tr:last-child {
      td {
        border-bottom: 0;
      }
    }

    tr {
      transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      will-change: background;

      &:hover {
        background: #eee;
      }
    }

    td {
      padding: 12px;
      color: rgba(0,0,0,0.87);
      font-weight: 400;
      font-size: 13px;
      text-align: left;
      border-bottom: 1px solid #ebebeb;

      span {
        font-size: 8px;
        opacity: 0.5;
      }

      &.center {
        text-align: center;
      }

      &.cell-label {
        font-weight: 500;
        text-align: left;
      }
    }
  }

  .player-club {
    border-left: 6px solid #fff;
  }

  .opta-stat-label {
    display: block;
    font-size: 8px;
    opacity: 0.5;
    text-align: right;
  }

  .powered-by {
    position: relative;
    top: 3px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 16px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);

    img {
      margin: 0 0 0 6px;
    }
  }

  .player-label-cell {
    text-align: left;
  }

  .player-stat-cell {
    text-align: right;
    border-left: 1px solid rgba(0,0,0,0.05);
  }
</style>
