const modules = require("../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
const AdvertisingFeature = require("../components/Advertising/Advertising2/Advertising2sdk.js")

const gcFeatures = {
  install(Vue) {
    Vue.prototype.$gcFeatures = function(feature) {
      const properties = modules.default[feature]

      switch (feature) {
        case "Advertising2":
          return new AdvertisingFeature.default(properties)

        default:
          break;
      }
    }
  }
}

export default gcFeatures
