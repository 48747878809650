const modules = require("../config/features/" + process.env.VUE_APP_CLUB_ID + "/modules.js")

export const devUsers = [
    "sergiearruda@urbanzoo.io",
    "connordeane@urbanzoo.io",
    "drewhughes@urbanzoo.io",
    "ahmedmcgarry@urbanzoo.io",
    "richgrannell@urbanzoo.io",
    "ralucaserdaru@urbanzoo.io",
]

class Permissions {
    static user

    static defineUser(user) {
        this.user = user
    }

    static getUserPermissions(feature) {
        let features
        const permissions = {}

        if (feature) {
            features = {
                [feature]: modules.default[feature]
            }
        } else {
            features = modules.default
        }

        for (const key in features) {
            if (typeof features[key] === "boolean") {
                // If it's a boolean, just copy the value over
                permissions[key] = features[key]
            } else {
                if (features[key].allowedRoles && features[key].allowedRoles.length > 0) {
                    // If allowed roles require a dev account, check the hard coded array of dev users
                    if (features[key].allowedRoles.includes("dev")) {
                        permissions[key] = devUsers.includes(this.user)
                    }
                    // TODO: when role specific access is required
                } else {
                    // If it's an object and there is nothing about allowedRoles, just allow it
                    permissions[key] = true
                }
            }
        }
        if (feature) {
            return permissions[feature]
        } else {
            return permissions
        }
    }

    static getRestrictedPermissions() {
        const restrictions = {}
        const features = modules.default

        for (const key in modules.default) {
            if (typeof features[key] === "boolean") {
                restrictions[key] = []
            } else {
                if (features[key].allowedRoles && features[key].allowedRoles.length > 0) {
                    restrictions[key] = features[key].allowedRoles
                } else {
                    restrictions[key] = []
                }
            }
        }
        return restrictions
    }
}


const gcPermissions = {
    install(Vue) {
        Vue.prototype.$gcPermissions = function () {
            return Permissions
        }
    }
}

export default gcPermissions
