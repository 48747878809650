<template>
  <section v-if="alerts.length" 
    :class="['system-alert', `system-alert--${alerts[0].alertType}`]"
    :style="`background: ${backgroundColor}; border-top: 4px solid ${borderColor}; color: ${textColor}`">
    <v-icon class="system-alert__icon" :color="iconColor">
      {{alerts[0].alertType != 'info' ? 'warning' : 'info'}}
    </v-icon>
    <div class="system-alert__text">
      <h3 class="system-alert__title">{{alerts[0].alertTitle}} <span class="system-alert__info"> - {{alerts[0].updatedAt | moment('DD/MM/YYYY (HH:mm:ss)')}}</span></h3>
      <p class="system-alert__info">{{alerts[0].alertInfo}}</p>
    </div>
  </section>
</template>

<script>
import { gql } from "graphql-request"

export default {
  name: "GlobalAlert",

  data: () => ({
    alerts: [],
    textColor: "black",
    backgroundColor: "#ebf5ff",
    borderColor: "#d2e4f4",
    iconColor: "#c2d7e9"
  }),

  mounted() {
    this.showAlert()
  },

  methods: {
    async showAlert() {
      const response = await this.$graphcms.request(
        gql`
          {
            globalAlerts(where: {enabled: true}) {
              id
              alertTitle
              alertInfo
              alertType
              updatedAt
            }
          }
        `
      );
      this.alerts = response.globalAlerts
      if (response.globalAlerts.length) {
        this.$emit("displayAlert", true)
        if (response.globalAlerts[0].alertType === "warning") {
          this.backgroundColor = "#ffd986"
          this.textColor = "black"
          this.borderColor = "#edc56f"
          this.iconColor =  "#deb45b"
        }
        else if (response.globalAlerts[0].alertType === "error") {
          this.backgroundColor = "#ff5252"
          this.textColor = "white"
          this.borderColor = "#ed4040"
          this.iconColor =  "#dd3030"
        }
      } else (
        this.$emit("displayAlert", false)
      )
    }
  }
}
</script>

<style scoped lang='scss'>

.system-alert {
  position: fixed;
  top: 0;
  z-index: 500;
  width: 100%;
  padding: 4px 16px 8px;
  display: flex;

  &__icon {
    margin-right: 10px;
  }

  &__title {
    font-size: 13px;
    font-weight: 500;
  }

  &__info {
    font-size: 11px;
    margin-bottom: 0px;
    font-weight: 400;
  }
}

</style>
