<template>

  <section v-if="articleList">
    <div v-for="post in articleList" :key="post.postID" class="article-card">
      <div v-if="post.imageData" :style="`background-image: url('${post.imageData.Location}')`" class="article-card__image"></div>
      <div v-else class="article-card__image"></div>

      <div class="article-card__content">
        <div>
          <span v-if="post.publishedDateTime" class="article-card__date">{{post.publishedDateTime | moment('D MMM YYYY')}}</span>
          <h4 v-if="post.postTitle" class="article-card__title">{{post.postTitle}}</h4>
        </div>

        <v-btn @click="fetchWidgets(post.postSlug)" color="accent">See More</v-btn>
      </div>

      <v-dialog v-model="dialog" width="650">
        <div class="article-card__modal">
          <h3 class="article-card__modal-title">{{post.postTitle}}</h3>
          <v-icon @click="dialog = false" class="article-card__close-button">close</v-icon>

          <div v-for="item in postData" :key="item.postID" class="article-card__widget-container">
            <img v-if="item.rowData.widgetType === 'ImageWidget'" :src="item.rowData.widgetData.image.Location" class="article-card__widget" />

            <div v-if="item.rowData.widgetType === 'TextBlockWidget'" v-html="item.rowData.widgetData.content" class="article-card__widget article-card__widget--text"></div>

            <div v-if="item.rowData.widgetType === 'VimeoWidget'" class="article-card__widget article-card__widget--vimeo intrinsic-block">
              <iframe :src="`https://player.vimeo.com/video/${item.rowData.widgetData.videoID}`" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
          </div>

          <p v-if="!postData.length">No further information available. Please let us know via Freshdesk.</p>

          <!-- <v-btn @click="openPostLink(post.postSlug)" class="article-card__link-button" color="accent">Read Full Article</v-btn> -->
        </div>
      </v-dialog>
    </div>

    <v-pagination
      v-if="pageCount > 1"
      v-model="pageNumber"
      :length="pageCount"
      circle
      color="accent"
      class="article-card__pagination">
    </v-pagination>
  </section>

  <p v-else>No {{category}} articles Found</p>

</template>

<script>
import axios from "axios"

export default {
  name: "ReleaseArticles",

  props: {
    category: {
      type: String,
      required: true
    }
  },

  data: () => ({
    articleList: null,
    pageNumber: 1,
    pageCount: "",
    dialog: false,
    postData: []
  }),

  watch: {
    pageNumber(value, oldValue) {
      console.log("Page number changed");
      if (oldValue) {
        this.fetchArticles()
      }
    },
  },

  mounted() {
    this.fetchArticles();
  },

  methods: {
    fetchArticles() {
      var pageSize = 1;
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `https://webapi.gc.uzfc.io/v1/news/?postCategory=/${this.category}&pageSize=${pageSize}&pageNumber=${this.pageNumber}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          this.articleList = response.data.body;
          this.pageCount = Math.ceil(response.data.totalCount / pageSize);
        })
        .catch(e => {
          console.error("Problem getting release articles", e);
        });
      })
    },

    fetchWidgets(slug) {
      this.postData = []

      axios({
        method: "GET",
        url: `https://webapi.gc.uzfc.io/v1/news/byslug/?postSlug=${slug}`
      })
      .then(response => {

        const postData = []
        const limit = 3
        let count = 1

        for (const item of response.data.body[0].content) {
          console.log("item", item)
          if (limit === count) {
            break;
          }
          if (item.rowData.widgetType === "TextBlockWidget" ||
              item.rowData.widgetType === "ImageWidget" ||
              item.rowData.widgetType === "VimeoWidget") {

                postData.push(item)
          }
          count = count + 1
        }

        this.postData = postData
      })
      .catch(e => {
        console.error("Problem fetching widgets", e);
      });

      this.dialog = true;
    },

    openPostLink(slug) {
      window.open(`https://livepreview.gc.uzfc.io${slug}`, "_blank");
    }
  }
}
</script>

<style scoped lang="scss">

  .article-card {
    width: 60%;
    margin: 24px auto;
    background-color: white;
    box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.12);
    border-radius: 8px;

    &__image {
      height: 225px;
      background: center / cover no-repeat;
      border: 2px solid #67247C;
      border-bottom: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &__content {
      padding: 4px 0 4px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 2px solid #67247C;
      border-top: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &__date {
      font-size: 11px;
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
    }

    &__pagination {
      display: flex;
      justify-content: center;
    }

    &__modal {
      min-height: 600px;
      max-height: 75vh;
      padding: 24px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      overflow: auto;
    }

    &__modal-title {
      padding: 0 0 24px;
      font-size: 16px;
      font-weight: 500;
    }

    &__close-button {
      position: absolute;
      top: 24px;
      right: 24px;
    }

    &__widget-container {
      max-width: 560px;
      width: 100%;
    }

    &__widget {
      width: 100%;
      margin: 0 0 24px;

      &--text {
        margin-top: 1.5em;

        /deep/ {
          p {
            margin-bottom: 1.5em;
            font-size: inherit;
          }

          ul {
            margin: 1em 0;
            padding: 0 0 0 1em;
            list-style: none;
            counter-reset: li;

            li {
              margin: 0 0 1em;
              font-size: 1em;
              position: relative;

              &:before {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                background-color: #212121;
                border-radius: 50%;
                top: 0.5em;
                left: -1em;
              }
            }
          }
        }
      }

      &--vimeo {
        position: relative;
        height: 0;
        padding-bottom: 315px / 560px * 100%;
        // margin: 0 auto;

        /deep/ iframe {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
    }

    &__link-button {
      margin-top: 24px;
    }
  }

</style>
