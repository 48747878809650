<template>
  <section class="build-notice ml-5 mr-5">

    <template v-if="mode === 'full'">
      <p class="build-notice__warning">If changes are made in this module you will need to trigger a full website build.</p>
      <v-btn @click="goToBuild()">Go to Website Manager</v-btn>
    </template>

    <template v-else-if="mode === 'home'">
      <p class="build-notice__warning">If changes are made in this module you will need to trigger a homepage build.</p>
      <v-btn @click="goToBuild()">Go to Website Manager</v-btn>
    </template>

    <template v-else-if="mode === 'partial'" >
      <p class="build-notice__warning">If changes are made to this area you will need to trigger a partial website build for the path {{buildRoute}}</p>
      <v-btn @click="goToPartialBuild(buildRoute)">Go to Website Manager</v-btn>
    </template>
  </section>
</template>

<script>
export default {
  name: "BuildNotice",

  props: {
    mode: {
      type: String,
      required: true
    },
    buildRoute: {
      type: String,
      required: false
    }
  },

  methods: {
    goToBuild() {
      this.$router.push("/website-management")
    },
    goToPartialBuild() {
      this.$router.push(`/website-management?routeToBuild=${this.buildRoute}`)
    }
  }
}
</script>

<style scoped lang='scss'>

  .build-notice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f6d2;
    padding: 8px 16px;
    border: 1px solid #d6d8b0;
    border-radius: 8px;

    &__warning {
      margin-bottom: 0;
    }
  }

</style>
