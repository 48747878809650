// Because of the way some of our apis work, this function turns empty inside an object into a null value, but it only checks for the values you specify as the second argument

const nullifyEmptyString = {

  install(Vue) {
    Vue.prototype.$nullifyEmptyString = function(data, properties) {
        const updatedObj = { ...data };

        properties.forEach(property => {
          const nestedProperties = property.split(".");
      
          let nestedObj = updatedObj;
          for (let i = 0; i < nestedProperties.length; i++) {
            const nestedProp = nestedProperties[i];
      
            if (nestedProp in nestedObj) {
              if (i === nestedProperties.length - 1) {
                if (typeof nestedObj[nestedProp] === "string" && nestedObj[nestedProp] === "") {
                  nestedObj[nestedProp] = null;
                }
              } else {
                nestedObj = nestedObj[nestedProp];
              }
            } else {
              break;
            }
          }
        });
      
        return updatedObj;
    }
  }
}

export default nullifyEmptyString
