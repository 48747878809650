<template>
    <v-app
        id="gamechanger"
        :class="showGlobalAlert ? 'global-alert' : ''"
    >
        <AppNavigation
            v-if="loggedIn === true"
            :class="showGlobalAlert ? 'global-alert' : ''"
        />
        <AppInfo
            v-if="sidebar"
            @displayReleaseNotes="displayReleaseNotes = true"
        />

        <release-notes-popup
            v-if="loggedIn === true"
            :dialog="displayReleaseNotes"
            @closeReleaseNotes="displayReleaseNotes = false"
            @newVersion="displayReleaseNotes = true"
        />

        <editorial-stats-container
            v-if="showOptaInsights && loggedIn === true"
            class="stats"
        />

        <transition name="fade">
            <image-editor v-if="$store.state.mediaLibrary.editImage && loggedIn === true" />
        </transition>

        <global-alert @displayAlert="showAlert($event)" />

        <v-toolbar
            :class="showGlobalAlert ? 'global-alert' : ''"
            v-if="loggedIn === true"
            color="white"
            light
            app
        >
            <v-toolbar-side-icon @click.stop="openNavigation()"></v-toolbar-side-icon>

            <v-spacer></v-spacer>

            <v-toolbar-items>
                <router-link
                    tag="button"
                    class="profile"
                    to="/profile"
                >
                    <v-img
                        src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                        class="profile__image"
                        cover
                        height="25"
                        width="25"
                    ></v-img>

                    <span class="profile__name">{{ loggedInAs }}</span>
                </router-link>
            </v-toolbar-items>
            <v-btn
                dark
                type="button"
                class="logout"
                v-on:click="logout()"
            >Logout</v-btn>
        </v-toolbar>

        <v-content>
            <v-progress-linear
                v-show="isLoading"
                :indeterminate="true"
                color="accent"
            ></v-progress-linear>
            <v-container
                fluid
                fill-height
                pt-0
                px-0
            >
                <v-layout
                    justify-start
                    align-start
                >
                    <main
                        class="main"
                        v-if="loggedIn ||
                            $route.path === '/login' ||
                            $route.path === '/login-forgot-password' ||
                            $route.path === '/login-set-password' ||
                            $route.path === '/login-reset-password'
                            "
                        :class="showGlobalAlert ? 'main--alert' : ''"
                    >
                        <v-breadcrumbs
                            :items="$route.matched"
                            divider="/"
                        >
                            <template
                                slot="item"
                                slot-scope="props"
                            >
                                <router-link
                                    tag="a"
                                    :to="props.item.path"
                                    class="breadcrumb"
                                >{{
                                    props.item.name
                                }}</router-link>
                            </template>
                        </v-breadcrumbs>

                        <v-fade-transition mode="out-in">
                            <router-view></router-view>
                        </v-fade-transition>
                    </main>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>


import AppNavigation from "@/components/AppNavigation";
import AppInfo from "@/components/AppInfo";

import ReleaseNotesPopup from "@/components/ReleaseNotes/ReleaseNotesPopup.vue";
import ImageEditor from "@/components/Media/V2/ImageEditor.vue";
import GlobalAlert from "@/components/GlobalAlert/GlobalAlert";


import EditorialStatsContainer from "./components/EditorialStats/EditorialStatsContainer.vue";

import * as Sentry from "@sentry/browser";


export default {
    name: "App",

    components: {
        AppNavigation,
        AppInfo,
        ImageEditor,
        ReleaseNotesPopup,
        EditorialStatsContainer,
        GlobalAlert,
    },

    computed: {
        sidebar: {
            get() {
                return this.$store.state.sidebar;
            },
        },

        isLoading: {
            get() {
                return this.$store.state.loading;
            },
        },
    },

    data() {
        return {
            loggedIn: false,
            loggedInAs: "",
            displayReleaseNotes: false,
            modules: null,
            showOptaInsights: false,
            showGlobalAlert: false,
        };
    },

    created() {
        console.log(
            `${process.env.VUE_APP_CLUB_NAME} - ${process.env.VUE_APP_SPORT}`
        );
        // console.log(`${process.env.VUE_APP_CLUB_ID}`);
        this.modules = require("./config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
        this.showOptaInsights = this.modules.default.OptaIntegration;
    },

    mounted() {
        //Upon initial mount we check the authentication state
        // console.log("Access Check 1", "route change", this.$route);
        if (this.$getAuthenticatedUser() != null) {
            // console.log("Access Check", "user authenticated");
            this.$getCurrentUserAttributes().subscribe(
                (userData) => {
                    //check if the user has completed their registration (mostly this will be Social Media Registrations, post linking of their account)
                    // console.log("Access Check", "checking registration is complete");
                    // console.log("User data", userData);
                    this.$store.commit("saveUserDetails", {
                        firstName: userData.given_name,
                        lastName: userData.family_name,
                        email: userData.email,
                    });

                    this.loggedIn = true;
                    this.loggedInAs = userData.email;

                    //always force logged in users to their profile, unless they are already going there
                    // if (this.$route.path !== "/" && this.$route.path) {
                    //     this.$router.push("/");
                    // }

                    Sentry.configureScope(function (scope) {
                        scope.setUser({
                            email: userData.email,
                            name: userData.given_name,
                        });
                    });
                },
                (err) => {
                    console.log(
                        "Access Check",
                        "Unable to load user profile, session may not be present."
                    );
                    console.log("2", err);
                    this.loggedIn = false;
                    if (err.code === "PasswordResetRequiredException") {
                        this.redirectToPasswordReset();
                    } else {
                        this.redirectToLogin();
                    }
                }
            );
        } else {
            console.log("Access Check", "no authenticated user present");
            this.loggedIn = false;
            this.redirectToLogin();
        }
    },

    watch: {
        $route() {
            //Upon each route change we check the authentication state

            // console.log("Access Check 2", "route change " + to);
            if (this.$getAuthenticatedUser() != null) {
                // console.log("Access Check", "user authenticated");
                this.$getCurrentUserAttributes().subscribe(
                    () => {
                        //check if the user has completed their registration (mostly this will be Social Media Registrations, post linking of their account)
                        // console.log('Access Check', 'checking registration is complete')
                        // console.log('User data', userData)
                        // console.log('Access Check userDate', userData)
                        this.loggedIn = true;
                    },
                    (err) => {
                        console.log(
                            "Access Check",
                            "Unable to load user profile, session may not be present."
                        );
                        console.log("1", err);
                        this.loggedIn = false;
                        if (err.code === "PasswordResetRequiredException") {
                            this.redirectToPasswordReset();
                        } else {
                            this.redirectToLogin();
                        }
                    }
                );
            } else {
                // console.log("Access Check", "no authenticated user present");
                this.loggedIn = false;
                this.redirectToLogin();
            }
        },
    },

    updated() {
        console.log(this.$route);
    },

    methods: {
        openNavigation() {
            if (this.sidebar) {
                this.$store.commit("closeSidebar");
            } else {
                this.$store.commit("openSidebar");
            }
        },
        redirectToPasswordReset() {
            if (this.$route.path !== "/login-reset-password") {
                console.log("redirecting to reset password");
                this.$router.push("/login-reset-password");
            }
        },
        redirectToLogin() {
            if (
                window.location.pathname != "/login" &&
                window.location.pathname != "/login-set-password" &&
                window.location.pathname != "/login-reset-password" &&
                window.location.pathname != "/login-forgot-password"
            ) {
                console.log("redirect to logiiiiin");
                this.$router.push("/login");
            }
        },

        logout() {
            this.$signOut();
            this.$router.push("/login");
        },

        showAlert(val) {
            this.showGlobalAlert = val;
        },
    },
};
</script>

<style lang="scss">
@import "/styles/global.scss";

.v-progress-linear {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0;
}

.fade-enter-active {
    animation: fade-in 0.5s;
}

.fade-leave-active {
    animation: fade-out 0.5s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>


<style lang="scss" scoped>
.main {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    padding-top: 6px;
    max-width: 100vw;

    &--alert {
        padding-top: 40px !important;
    }
}

.v-breadcrumbs {
    padding: 25px 0 10px var(--body-gutter);
    background-color: #fff;
}

.breadcrumb {
    color: #354052;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &--active {
        color: green;
    }
}

.profile {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;

    &__image {
        margin-right: 8px;
        border-radius: 50%;
    }

    &__name {
        font-size: 12px;
        margin-right: 12px;
    }
}

.logout {
    margin-right: 40px;
}

.global-alert {
    top: 50px !important;
}
</style>
