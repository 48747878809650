import Vue from "vue";
import Router from "vue-router";
import Dashboard from "./views/Dashboard.vue";
import NotFound from "./views/NotFound.vue";

Vue.use(Router);

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "Dashboard",
            component: Dashboard,
        },
        {
            path: "/global-settings",
            name: "Global Settings",
            // route level code-splitting
            // this generates a separate chunk (global-settings.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import(
          /* webpackChunkName: "global-settings" */ "./views/GlobalSettings.vue"
                ),
        },
        {
            path: "/news",
            name: "News",
            component: () =>
                import(/* webpackChunkName: "news" */ "./views/News.vue"),

            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "PageManagement" */ "./components/News/NewsManagement.vue"
                        ),
                },
                {
                    path: "/news/:id",
                    name: "News Editor",
                    component: () =>
                        import(
              /* webpackChunkName: "PageContent" */ "./components/News/NewsContent.vue"
                        ),
                },
            ],
        },
        // {
        //   path: '/media',
        //   name: 'Media',
        //   component: () =>
        //     import ( /* webpackChunkName: "news" */ './views/Media.vue'),

        //   children: [{
        //       path: '/',
        //       component: () =>
        //         import ( /* webpackChunkName: "PageManagement" */ './components/Media/MediaManagement.vue'),
        //     },
        //     {
        //       path: '/media/:id',
        //       name: 'Media Editor',
        //       component: () =>
        //         import ( /* webpackChunkName: "PageContent" */ './components/Media/MediaItem.vue'),
        //     }
        //   ]
        // },
        {
            path: "/media",
            name: "Media",
            component: () =>
                import(/* webpackChunkName: "news" */ "./views/Media.vue"),

            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "PageManagement" */ "./components/Media/V2/MediaManagementV2.vue"
                        ),
                },
                {
                    path: "/media/:id",
                    name: "Media Editor",
                    component: () =>
                        import(
              /* webpackChunkName: "PageContent" */ "./components/Media/MediaItem.vue"
                        ),
                },
            ],
        },
        {
            path: "/advertising",
            name: "Advertising",
            component: () =>
                import(/* webpackChunkName: "Advertising" */ "./views/Advertising.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "AdvertisingManager" */ "./components/Advertising/Advertising2/AdvertisingManager2.vue"
                        ),
                },
            ],
        },
        {
            path: "/forms",
            name: "Forms",
            component: () =>
                import(/* webpackChunkName: "Forms" */ "./views/Forms.vue"),
            children: [
                // {
                //   path: '/',
                //   component: () =>
                //     import ( /* webpackChunkName: "FormsDashboard" */ './components/Forms/FormsDashboard.vue'),
                // },
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "FormsManagement" */ "./components/Forms/FormsManagement.vue"
                        ),
                },
                // {
                //   path: '/forms/manage',
                //   component: () =>
                //     import ( /* webpackChunkName: "FormsManagement" */ './components/Forms/FormsManagement.vue' ),
                // },
                // {
                //   path: '/forms/manage',
                //   component: () =>
                //     import ( /* webpackChunkName: "FormsManagement" */ './components/Forms/FormsManagement.vue' ),
                // },
                // {
                //   path: '/forms/responses',
                //   component: () =>
                //     import ( /* webpackChunkName: "FormsManagement" */ './components/Forms/FormResponses.vue' ),
                // },
                // {
                //   path: '/forms/create',
                //   component: () =>
                //     import ( /* webpackChunkName: "FormEdit" */ './components/Forms/FormEdit.vue' )
                // },
                {
                    path: "/forms/:id",
                    name: "Form Edit",
                    props: true,
                    component: () =>
                        import(
              /* webpackChunkName: "FormEdit" */ "./components/Forms/FormEdit.vue"
                        ),
                },
            ],
        },
        {
            path: "/documents",
            name: "Documents",
            component: () =>
                import(/* webpackChunkName: "Documents" */ "./views/Documents.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "DocumentsManagement" */ "./components/Documents/DocumentsManagement.vue"
                        ),
                },
                {
                    path: "/documents/:id",
                    name: "Documents Edit",
                    props: true,
                    component: () =>
                        import(
              /* webpackChunkName: "DocumentsEdit" */ "./components/Documents/DocumentEdit.vue"
                        ),
                },
            ],
        },
        {
            path: "/website-management",
            name: "Website Management",
            component: () =>
                import(
          /* webpackChunkName: "WebsiteManager" */ "./views/WebsiteManager.vue"
                ),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "WebsiteManager" */ "./components/WebsiteManager/WebsiteManager.vue"
                        ),
                },
            ],
        },
        {
            path: "/app-management",
            name: "App Management",
            component: () =>
                import(/* webpackChunkName: "AppConfig" */ "./views/AppConfig.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "AppDashboard" */ "./components/AppConfig/AppDashboard.vue"
                        ),
                },
                {
                    path: "/app-management/ticketing-links",
                    name: "Ticketing Links Manager",
                    component: () =>
                        import(
              /* webpackChunkName: "TicketingLinksManager" */ "./components/AppConfig/TicketingLinksManager.vue"
                        ),
                },
                {
                    path: "/app-management/ecommerce-links",
                    name: "Ecommerce Links Manager",
                    component: () =>
                        import(
              /* webpackChunkName: "EcommerceLinksManager" */ "./components/AppConfig/EcommerceLinksManager.vue"
                        ),
                },
                {
                    path: "/app-management/social-links",
                    name: "Social Links Manager",
                    component: () =>
                        import(
              /* webpackChunkName: "SocialLinksManager" */ "./components/AppConfig/SocialLinksManager.vue"
                        ),
                },
                {
                    path: "/app-management/app-configuration",
                    name: "Config Manager",
                    component: () =>
                        import(
              /* webpackChunkName: "AppSettings" */ "./components/PlatformSettings/AppSettings.vue"
                        ),
                },
                {
                    path: "/app-management/rewards-manager",
                    name: "Rewards Manager",
                    component: () =>
                        import(
              /* webpackChunkName: "RewardsManager" */ "./components/Rewards/RewardsManager.vue"
                        ),
                },
            ],
        },
        {
            path: "/sponsors",
            name: "Sponsors",
            component: () =>
                import(/* webpackChunkName: "Advertising" */ "./views/Sponsors.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "ConfigSelector" */ "./components/Sponsors/SponsorsSelector.vue"
                        ),
                },
                {
                    path: "/sponsors/footer",
                    name: "Footer Sponsors",
                    component: () =>
                        import(
              /* webpackChunkName: "HomepageEditor" */ "./components/Sponsors/Subpages/FooterSponsors/FooterSponsors.vue"
                        ),
                },
                {
                    path: "/sponsors/loading-screen",
                    name: "App Loading Screen",
                    props: {
                        appID: "loadingScreen_App",
                    },
                    component: () =>
                        import(
              /* webpackChunkName: "HomepageEditor" */ "./components/Sponsors/Subpages/SectionSponsors/SectionSponsors.vue"
                        ),
                },
                {
                    path: "/sponsors/homepage-news",
                    name: "Homepage News",
                    props: {
                        appID: "homepageNews_App",
                        webID: "homepageNews_Web",
                    },
                    component: () =>
                        import(
              /* webpackChunkName: "HomepageEditor" */ "./components/Sponsors/Subpages/SectionSponsors/SectionSponsors.vue"
                        ),
                },
                {
                    path: "/sponsors/news-listing",
                    name: "News Listing Page",
                    props: {
                        appID: "newsListing_App",
                        webID: "newsListing_Web",
                    },
                    component: () =>
                        import(
              /* webpackChunkName: "HomepageEditor" */ "./components/Sponsors/Subpages/SectionSponsors/SectionSponsors.vue"
                        ),
                },
                {
                    path: "/sponsors/match-centre-first-team",
                    name: "Match Centre - First Team",
                    props: {
                        appID: "matchCentre_FirstTeam_App",
                        webID: "matchCentre_FirstTeam_Web",
                    },
                    component: () =>
                        import(
              /* webpackChunkName: "HomepageEditor" */ "./components/Sponsors/Subpages/SectionSponsors/SectionSponsors.vue"
                        ),
                },
                {
                    path: "/sponsors/fixtures-page-first-team",
                    name: "Fixtures Page - First Team",
                    props: {
                        appID: "fixturesPage_FirstTeam_App",
                        webID: "fixturesPage_FirstTeam_Web",
                    },
                    component: () =>
                        import(
              /* webpackChunkName: "HomepageEditor" */ "./components/Sponsors/Subpages/SectionSponsors/SectionSponsors.vue"
                        ),
                },
                {
                    path: "/sponsors/player-of-the-match",
                    name: "Player of the Match",
                    props: {
                        appID: "playerOfTheMatch_App",
                        webID: "playerOfTheMatch_Web",
                    },
                    component: () =>
                        import(
              /* webpackChunkName: "HomepageEditor" */ "./components/Sponsors/Subpages/SectionSponsors/SectionSponsors.vue"
                        ),
                },
                {
                    path: "/sponsors/score-predictor",
                    name: "Score Predictor",
                    props: {
                        appID: "scorePredictor_App",
                        webID: "scorePredictor_Web",
                    },
                    component: () =>
                        import(
              /* webpackChunkName: "HomepageEditor" */ "./components/Sponsors/Subpages/SectionSponsors/SectionSponsors.vue"
                        ),
                },
            ],
        },
        {
            path: "/team-management",
            name: "Team Management",
            component: () =>
                import(
          /* webpackChunkName: "TeamManagement" */ "./views/TeamManagement.vue"
                ),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "FixturesManager" */ "./components/TeamManagement/TeamManagement.vue"
                        ),
                },
                {
                    name: "Team Crests",
                    path: "/team-management/team-crests",
                    component: () =>
                        import(
              /* webpackChunkName: "TeamManagement" */ "./components/TeamCrests/TeamCrestManager.vue"
                        ),
                },
                {
                    path: "/team-management/fixtures",
                    name: "Fixtures",
                    component: () =>
                        import(/* webpackChunkName: "fixtures" */ "./views/Fixtures.vue"),
                    children: [
                        {
                            path: "/",
                            component: () =>
                                import(
                  /* webpackChunkName: "FixturesManager" */ "./components/Fixtures/FixturesManager.vue"
                                ),
                        },
                        {
                            path: "/team-management/fixtures/:matchID",
                            name: "Fixture Editor",
                            props: true,
                            component: () =>
                                import(
                  /* webpackChunkName: "FixtureEditor" */ "./components/Fixtures/FixtureEditor.vue"
                                ),
                        },
                    ],
                },
                {
                    path: "/team-management/squads",
                    name: "Squads",
                    component: () =>
                        import(/* webpackChunkName: "squads" */ "./views/Squads.vue"),
                    children: [
                        {
                            path: "/",
                            component: () =>
                                import(
                  /* webpackChunkName: "SquadsManagement" */ "./components/Squads/SquadsManagement.vue"
                                ),
                        },
                        {
                            path: "/team-management/player/:playerID",
                            name: "Player Editor",
                            component: () =>
                                import(
                  /* webpackChunkName: "PlayerManagement" */ "./components/Squads/PlayerManagement.vue"
                                ),
                        },
                        {
                            path: "/team-management/player/:playerID",
                            name: "Player Editor V3",
                            component: () =>
                                import(
                  /* webpackChunkName: "PlayerManagementV3" */ "./components/Squads/PlayerManagementV3.vue"
                                ),
                        },
                        {
                            path: "/team-management/tables/:tableID",
                            name: "Table Editor",
                            component: () =>
                                import(
                  /* webpackChunkName: "CustomTableEdit" */ "./components/Squads/CustomTableEdit.vue"
                                ),
                        },
                    ],
                },
            ],
        },
        {
            path: "/streamline",
            name: "Streamline",
            component: () =>
                import(/* webpackChunkName: "videos" */ "./views/Streamline.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "VideoManagement" */ "./components/Streamline/VideoManagement.vue"
                        ),
                },
                {
                    path: "/streamline/ondemand/:id",
                    name: "Video Content",
                    component: () =>
                        import(
              /* webpackChunkName: "VideoContent" */ "./components/Streamline/VideoContent.vue"
                        ),
                },
                {
                    path: "/streamline/live/:id",
                    name: "Live Stream",
                    component: () =>
                        import(
              /* webpackChunkName: "LiveStreamingContent" */ "./components/Streamline/LiveStreamingContent.vue"
                        ),
                },
                {
                    path: "/streamline/georestriction-profiles/:id",
                    name: "Georestriction Profiles",
                    component: () =>
                        import(
              /* webpackChunkName: "GeorestrictionProfileEdit" */ "./components/Streamline/Tools/GeorestrictionProfiles/GeorestrictionProfileEdit.vue"
                        ),
                },
                {
                    path: "/streamline/package/create-package",
                    name: "Create Package",
                    component: () =>
                        import(
              /* webpackChunkName: "LiveStreamingContent" */ "./components/Streamline/PackagesContent.vue"
                        ),
                },
                {
                    path: "/streamline/package/:id",
                    name: "Edit Package",
                    component: () =>
                        import(
              /* webpackChunkName: "LiveStreamingContent" */ "./components/Streamline/PackagesContent.vue"
                        ),
                },
            ],
        },
        {
            path: "/calendar",
            name: "Calendar",
            component: () =>
                import(/* webpackChunkName: "videos" */ "./views/Calendar.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "VideoManagement" */ "./components/Calendar/CalendarListing.vue"
                        ),
                },
                {
                    path: "/calendar/:id",
                    name: "Calendar Edit",
                    component: () =>
                        import(
              /* webpackChunkName: "VideoContent" */ "./components/Calendar/Edit/CalendarEdit.vue"
                        ),
                },
            ],
        },
        {
            path: "/pages",
            name: "Pages",
            component: () =>
                import(/* webpackChunkName: "pages" */ "./views/Pages.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "PageManagement" */ "./components/Pages/PageManagementNewMove.vue"
                        ),
                },
                {
                    path: "/pages/legacy",
                    name: "Legacy",
                    component: () =>
                        import(
              /* webpackChunkName: "PageContent" */ "./components/Pages/PageManagement.vue"
                        ),
                },
                {
                    path: "/pages/:id",
                    name: "Page Content",
                    component: () =>
                        import(
              /* webpackChunkName: "PageContent" */ "./components/Pages/PageContent.vue"
                        ),
                },
            ],
        },
        {
            path: "/content-feed",
            name: "Content Feed",
            component: () =>
                import(/* webpackChunkName: "ContentFeed" */ "./views/ContentFeed.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "ContentFeedManager" */ "./components/ContentFeed/ContentFeedManager.vue"
                        ),
                },
            ],
        },
        {
            path: "/blog",
            name: "Blog",
            component: () =>
                import(/* webpackChunkName: "blog" */ "./views/Blog.vue"),

            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "PageManagement" */ "./components/Blog/BlogListing.vue"
                        ),
                },
                {
                    path: "/blog/:id",
                    name: "Blog Editor",
                    component: () =>
                        import(
              /* webpackChunkName: "PageContent" */ "./components/Blog/BlogManagement.vue"
                        ),
                },

            ],
        },
        {
            path: "/broadcasters",
            name: "Broadcasters",
            component: () =>
                import(/* webpackChunkName: "Broadcasters" */ "./views/Broadcasters.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "BroadcastersListing" */ "./components/Broadcasters/BroadcastersListing.vue"
                        ),
                },
            ],
        },
        {
            path: "/judo",
            name: "Judo",
            component: () =>
                import(/* webpackChunkName: "Rover" */ "./views/Judo.vue"),

            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "RoverDashboard" */ "./components/Judo/JudoDashboard.vue"
                        ),
                },
                {
                    path: "/judo/:id",
                    name: "Editor",
                    component: () =>
                        import(
              /* webpackChunkName: "RoverItem" */ "./components/Judo/JudoItem.vue"
                        ),
                },
            ],
        },
        {
            path: "/organisations",
            name: "Organisations",
            component: () =>
                import(
          /* webpackChunkName: "Organisations" */ "./views/Organisations.vue"
                ),

            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "OrganisationsManager" */ "./components/Organisations/OrganisationsManager.vue"
                        ),
                },
                {
                    path: "/organisations/:id",
                    name: " Edit Organisation",
                    props: true,
                    component: () =>
                        import(
              /* webpackChunkName: "EditOrganisation" */ "./components/Organisations/EditOrganisation.vue"
                        ),
                },
                {
                    path: "/organisationTypes/:id",
                    name: " Edit Organisation Type",
                    props: true,
                    component: () =>
                        import(
              /* webpackChunkName: "EditOrganisation" */ "./components/Organisations/EditOrganisationType.vue"
                        ),
                },
            ],
        },
        {
            path: "/map-directory",
            name: "Map Directory",
            component: () =>
                import(
          /* webpackChunkName: "MapDirectoryManager" */ "./views/MapDirectory.vue"
                ),

            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "MapDirectoryManager" */ "./components/MapDirectory/MapDirectoryManager.vue"
                        ),
                },
                {
                    path: "/map-directory/categories/:category_id/locations-list",
                    name: "Locations List",
                    props: true,
                    component: () =>
                        import(
              /* webpackChunkName: "MapLocationsList" */ "./components/MapDirectory/Locations/MapLocationsList.vue"
                        ),
                },
                {
                    path: "/map-directory/categories/:category_id/locations-list/:location_id",
                    name: "Create Locations",
                    props: true,
                    component: () =>
                        import(
              /* webpackChunkName: "CreateMapLocation" */ "./components/MapDirectory/Locations/EditMapLocations.vue"
                        ),
                },
                {
                    path: "/map-directory/categories/:id",
                    name: "Create Category",
                    props: true,
                    component: () =>
                        import(
              /* webpackChunkName: "CreateMapCateogory" */ "./components/MapDirectory/Categories/EditMapCategories.vue"
                        ),
                },
                {
                    path: "/map-directory/:id",
                    name: "Create Map",
                    props: true,
                    component: () =>
                        import(
              /* webpackChunkName: "CreateMapDirectory" */ "./components/MapDirectory/EditMapDirectory.vue"
                        ),
                },
            ],
        },
        {
            path: "/video-management",
            name: "Videos Management",
            component: () =>
                import(
          /* webpackChunkName: "Memberships" */ "./views/VideosManager.vue"
                ),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "ConfigManager" */ "./components/VideosManager/VideosManagement.vue"
                        ),
                },
                {
                    name: "Video Content",
                    path: "content",
                    component: () =>
                        import(
              /* webpackChunkName: "TeamManagement" */ "./components/VideosManager/Sections/ContentManager.vue"
                        ),
                    children: [
                        {
                            path: "content-feeds/:id",
                            name: "Edit Feed",
                            props: true,
                            component: () =>
                                import(
                  /* webpackChunkName: "EditMemberships" */ "./components/VideosManager/Data/VideoContentFeeds/VideoContentFeedEdit.vue"
                                ),
                        },
                        {
                            path: "content-feeds/create",
                            name: "Create Feed",
                            props: true,
                            component: () =>
                                import(
                  /* webpackChunkName: "EditMemberships" */ "./components/VideosManager/Data/VideoContentFeeds/VideoContentFeedEdit.vue"
                                ),
                        },
                    ],
                },
                {
                    name: "Video Config",
                    path: "/video-management/config",
                    component: () =>
                        import(
              /* webpackChunkName: "TeamManagement" */ "./components/VideosManager/Sections/ConfigManager.vue"
                        ),
                    children: [
                        {
                            path: "categories/create",
                            name: "Create Category",
                            props: true,
                            component: () =>
                                import(
                  /* webpackChunkName: "EditCategories" */ "./components/VideosManager/Data/VideoCategories/EditCategories.vue"
                                ),
                        },
                        {
                            path: "categories/:id",
                            name: "Edit Category",
                            props: true,
                            component: () =>
                                import(
                  /* webpackChunkName: "EditCategories" */ "./components/VideosManager/Data/VideoCategories/EditCategories.vue"
                                ),
                        },
                    ],
                },
                {
                    name: "Memberships",
                    path: "/video-management/memberships",
                    component: () =>
                        import(
              /* webpackChunkName: "TeamManagement" */ "./components/VideosManager/Sections/MembershipsManager.vue"
                        ),
                    children: [
                        {
                            path: "/video-management/memberships/create-membership",
                            name: "Create Membership",
                            component: () =>
                                import(
                  /* webpackChunkName: "EditMemberships" */ "./components/VideosManager/Data/VideoMemberships/EditMembership.vue"
                                ),
                        },
                        {
                            path: "/video-management/memberships/:id",
                            name: "Edit Membership",
                            component: () =>
                                import(
                  /* webpackChunkName: "EditMemberships" */ "./components/VideosManager/Data/VideoMemberships/EditMembership.vue"
                                ),
                        },
                    ],
                },
                {
                    name: "Streamline",
                    path: "/video-management/streamline",
                    component: () =>
                        import(
              /* webpackChunkName: "TeamManagement" */ "./components/VideosManager/Sections/StreamlineManager.vue"
                        ),
                },
            ],
        },
        {
            path: "/promos",
            name: "Promos",
            component: () =>
                import(/* webpackChunkName: "pages" */ "./views/Promos.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "PageManagement" */ "./components/Promos/PromoManagement.vue"
                        ),
                },
                {
                    path: "/promos/:id",
                    name: "Promo Editor",
                    component: () =>
                        import(
              /* webpackChunkName: "PageContent" */ "./components/Promos/PromoEditor.vue"
                        ),
                },
            ],
        },
        {
            path: "/navigation",
            name: "Navigation",
            component: () =>
                import(/* webpackChunkName: "navigation" */ "./views/Navigation.vue"),
            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "navigation" */ "./components/Navigation/NavigationAll.vue"
                        ),
                },
                {
                    path: "/navigation/create",
                    name: "Create",
                    component: () =>
                        import(
              /* webpackChunkName: "NavigationSingle" */ "./components/Navigation/NavigationSingle.vue"
                        ),
                },
                {
                    path: "/navigation/:id",
                    name: "Edit",
                    component: () =>
                        import(
              /* webpackChunkName: "NavigationSingle" */ "./components/Navigation/NavigationSingle.vue"
                        ),
                },
            ],
        },
        {
            path: "/match-centre",
            name: "Match Centre",
            component: () =>
                import(
          /* webpackChunkName: "match-centre" */ "./views/MatchCentre.vue"
                ),
        },
        {
            path: "/platforms",
            name: "Platforms",
            component: () =>
                import(/* webpackChunkName: "platforms" */ "./views/Platforms.vue"),
        },
        {
            path: "/dashboard",
            name: "Dashboard",
            component: () =>
                import(/* webpackChunkName: "platforms" */ "./views/Dashboard.vue"),
        },
        {
            path: "/login",
            name: "Login",
            component: () =>
                import(/* webpackChunkName: "platforms" */ "./views/Login.vue"),
        },
        {
            // Used if a new user is required to change their password upon login
            path: "/login-set-password",
            name: "LoginSetPassword",
            component: () =>
                import(
          /* webpackChunkName: "platforms" */ "./views/LoginSetNewPassword.vue"
                ),
        },
        {
            // Used if a password reset request has been performed and the user needs to set their new password using the emailed reset code
            path: "/login-reset-password",
            name: "LoginResetPassword",
            component: () =>
                import(
          /* webpackChunkName: "platforms" */ "./views/LoginResetPassword.vue"
                ),
        },
        {
            // Used by a user to perform a password reset
            path: "/login-forgot-password",
            name: "Password Reset",
            component: () =>
                import(
          /* webpackChunkName: "platforms" */ "./views/LoginForgotPassword.vue"
                ),
        },
        {
            // Used for testing new experimental features
            path: "/lab",
            name: "Lab",
            component: () => import(/* webpackChunkName: "lab" */ "./views/Lab.vue"),
            children: [
                {
                    path: "/",
                    name: "Advertising",
                    component: () =>
                        import(
              /* webpackChunkName: "Lab" */ "./components/Experiments/AdvertisingModuleV1/AdvertisingModuleV1Dashboard.vue"
                        ),
                },
            ],
        },
        {
            path: "/admin-users",
            name: "Admin",
            component: () =>
                import(/* webpackChunkName: "pages" */ "./views/Admin.vue"),
            children: [
                {
                    path: "/",
                    name: "Users",
                    component: () =>
                        import(
              /* webpackChunkName: "PageManagement" */ "./components/Admin/AdminUsers.vue"
                        ),
                },
                {
                    path: "/admin-users/edit/:id",
                    name: " Edit",
                    component: () =>
                        import(
              /* webpackChunkName: "PageContent" */ "./components/Admin/AdminUserEdit.vue"
                        ),
                },
                {
                    path: "/admin-users/add/",
                    name: " Add",
                    component: () =>
                        import(
              /* webpackChunkName: "PageContent" */ "./components/Admin/AdminUserAdd.vue"
                        ),
                },
            ],
        },
        {
            path: "/users",
            name: "Users",
            component: () =>
                import(/* webpackChunkName: "pages" */ "./views/Admin.vue"),
            children: [
                {
                    path: "/",
                    name: "Search",
                    component: () =>
                        import(
              /* webpackChunkName: "PageManagement" */ "./components/Users/Cognito/Users.vue"
                        ),
                },
                {
                    path: "/users/edit/:id",
                    name: " User Edit",
                    component: () =>
                        import(
              /* webpackChunkName: "UserEditContainer" */ "./components/Users/Cognito/UserEdit/UserEditContainer.vue"
                        ),
                },
                {
                    path: "/users/add/",
                    name: "Add New User",
                    component: () =>
                        import(
              /* webpackChunkName: "PageContent" */ "./components/Users/Cognito/UserAdd.vue"
                        ),
                },
                {
                    path: "/users/create-entitlement/",
                    name: " Create User Entitlement",
                    component: () =>
                        import(
              /* webpackChunkName: "UserCreate" */ "./components/Users/Cognito/UserCreate/UserCreate.vue"
                        ),
                },
            ],
        },
        {
            // Used if a password reset request has been performed and the user needs to set their new password using the emailed reset code
            path: "/profile",
            name: "Profile",
            component: () =>
                import(/* webpackChunkName: "platforms" */ "./views/Profile.vue"),
        },
        {
            path: "/overlay-promos",
            name: "Overlay Promos",
            component: () =>
                import(
          /* webpackChunkName: "OverlayPromos" */ "./views/OverlayPromos.vue"
                ),

            children: [
                {
                    path: "/",
                    component: () =>
                        import(
              /* webpackChunkName: "OverlayPromosDashboard" */ "./components/OverlayPromos/OverlayPromosDashboard.vue"
                        ),
                },
                {
                    path: "/overlay-promos/:id",
                    name: "Editor",
                    component: () =>
                        import(
              /* webpackChunkName: "OverlayPromo" */ "./components/OverlayPromos/OverlayPromo.vue"
                        ),
                },
            ],
        },
        {
            path: "/404",
            component: NotFound,
        },
        {
            path: "*",
            redirect: "/404",
        },
    ],
});
