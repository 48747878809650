import Vue from "vue";
import { version } from "../package.json";
import Vuetify from "vuetify";
//@ts-ignore
import App from "./App.vue"
import "./plugins/vuetify"
import router from "./router"
import store from "./store"
import { GraphQLClient } from "graphql-request"
import UUID from "vue-uuid";
import gcPublisher from "./plugins/publisher.js";
import gcFeatures from "./plugins/features.js";
import gcPermissions from "./plugins/permissions";
import nullifyFalsyValues from "./plugins/utils.js";
import CognitoPlugin from "./cognito-plugin.js";
//@ts-ignore
import { Datetime } from "vue-datetime";
//@ts-ignore
import VueMoment from "vue-moment";
//@ts-ignore
import moment from "moment-timezone";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
Vue.config.productionTip = false

const graphcmsclient = new GraphQLClient(
    "https://api-eu-west-2.graphcms.com/v2/cl39y5wee00uk01z2fps4a2w4/master"
);
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";


Sentry.init({
    dsn: "https://41afe42c65804d1e95e340547f3423b9@o392955.ingest.sentry.io/5241336",
    integrations: [
        new VueIntegration({
            Vue,
            attachProps: true,
            logErrors: true,
        }),
    ],
});

Sentry.configureScope(function (scope) {
    scope.setTag("platform.version", version);
    scope.setTag("platform.client", process.env.VUE_APP_CLUB_NAME);
    scope.setTag("platform.context", process.env.VUE_APP_SPORT);

    scope.setExtra("platform.version", version);
    scope.setExtra("platform.client", process.env.VUE_APP_CLUB_NAME);
    scope.setExtra("platform.context", process.env.VUE_APP_SPORT);
});

Vue.use(Vuetify, {
    breakpoint: {
        thresholds: {
            lg: 1000,
        },
    },
    theme: {
        accent: "#67247C",
        lightgrey: "#ccc",
        grey: "#979797",
    },
});

Vue.mixin({
    beforeCreate() {
        // @ts-ignore
        this.$graphcms = graphcmsclient
    }
})
//@ts-ignore
Vue.use(UUID);
Vue.use(VueMoment, {
    moment,
});
Vue.use(CognitoPlugin);
Vue.use(gcPublisher);
Vue.use(gcFeatures);
Vue.use(gcPermissions);
Vue.use(nullifyFalsyValues);

Vue.component("datetime", Datetime);

const vueinstance = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")
//@ts-ignore
window.vueinstance = vueinstance
