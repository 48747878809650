<template>
  <section class="homepage-takeover">
    <build-notice mode="home" class="homepage-takeover__alert" />
    <p class="homepage-takeover__heading">Homepage Takeover</p>
    <v-layout row class="align-bottom">
      <v-flex mr-2>
        <fixture-details
          :restrictSubTeams="true"
          :fetchedMatchData="fetchedMatchData"
          @fetchMatchData="updateMatchData($event)"
        >
          Choose the fixture you want to promote on the homepage and across the
          site
        </fixture-details>
      </v-flex>
    </v-layout>

    <section v-if="matchDetails" class="match-details">
      <v-btn
        v-if="!homepageTakeoverID"
        color="accent"
        outline
        flat
        @click="saveHomepageTakeover()"
      >
        Save Homepage Takeover
      </v-btn>

      <v-btn
        v-if="homepageTakeoverID"
        color="accent"
        outline
        flat
        @click="clearHomepageTakeover()"
      >
        Clear Homepage Takeover
      </v-btn>

      <v-btn
        v-if="homepageTakeoverID"
        class="button--match-centre"
        color="accent"
        outline
        flat
        @click="gotoFixture(homepageTakeoverID)"
      >
        Go To Match Centre
      </v-btn>
    </section>
  </section>
</template>

<script>
// Commented template refers to the previous version
import axios from "axios";
import moment from "moment";
import BuildNotice from "@/components/Library/BuildNotice/BuildNotice";
import FixtureDetails from "@/components/Library/FixtureDetails";

export default {
  name: "MatchdayTakeover",

  components: {
    BuildNotice,
    FixtureDetails,
  },

  props: {
    teamID: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    fetchedMatchData: null,
    homepageTakeoverID: null,
    matchDetails: null,

    // Make this pass through to api
    homepageTakeoverStyles: {
      homeStyles: {},
      awayStyles: {},
    },
  }),

  mounted() {
    this.fetchHomepageTakeover();
  },

  methods: {
    gotoFixture(matchID) {
      this.$router.push({
        name: "Fixture Editor",
        params: {
          matchID: matchID,
          teamID: this.teamID,
          seasonID: process.env.VUE_APP_CURRENTSEASON,
        },
      });
    },

    fetchMatchData() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(
            `${process.env.VUE_APP_BASEURL}/fixtures/opta/getsingle?seasonID=${process.env.VUE_APP_CURRENTSEASON}&teamID=${this.teamID}&matchID=${this.homepageTakeoverID}`,
            { headers: { Authorization: jwt } }
          )
          .then((response) => {
            // console.log("fetchMatchData response: ", response);
            this.matchDetails = response.data.body.Items[0];
            (this.fetchedMatchData = {
              teamID: this.matchDetails.homeTeamID,
              seasonID: this.matchDetails.seasonID,
              matchDetails: {
                matchID: this.matchDetails.matchID,
                matchTeams:
                  this.matchDetails.teamData[0].teamName +
                  " vs " +
                  this.matchDetails.teamData[1].teamName,
                matchDate: moment(this.matchDetails.kickOffUTC).format(
                  "DD MMM YYYY - HH:mm"
                ),
              },
            }),
              this.$store.commit("completeLoading");
          });
      });
    },

    updateMatchData(payload) {
      this.matchDetails = payload;
      this.newHomepageTakeoverID = payload.matchDetails.matchID;
    },

    fetchHomepageTakeover() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${process.env.VUE_APP_BASEURL}/homepagetakeover`, {
            headers: { Authorization: jwt },
          })
          .then((response) => {
            // console.log('Homepage Takeover: ', response);

            this.homepageTakeoverID = response.data.homepageTakeoverMatchID;
            this.homepageTakeoverStyles = response.data.homepageTakeoverStyles;

            if (this.homepageTakeoverID) {
              this.fetchMatchData();
            } else {
              this.$store.commit("completeLoading");
            }
          })
          .catch((e) => {
            console.error("Problem retrieving homepage takeover", e);
            this.$store.commit("completeLoading");
          });
      });
    },

    saveHomepageTakeover(clear) {
      // console.log("saveHomepageTakeover", this.homepageTakeoverID);

      if (confirm("Are you sure you want to change the homepage takeover?")) {
        if (clear) {
          this.fetchedMatchData = null;
          this.homepageTakeoverID = null;
          this.matchDetails = null;
        } else {
          this.homepageTakeoverID = this.newHomepageTakeoverID;
        }

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_BASEURL}/homepagetakeover`,
            headers: { Authorization: jwt },
            data: {
              homepageTakeoverMatchID:
                this.homepageTakeoverID != "" ? this.homepageTakeoverID : null,
              homepageTakeoverStyles: null,
            },
          })
            .then((response) => {
              // console.log("Saved homepage takeover:", response);
              this.$store.commit("completeLoading");

              this.$gcPublish(["/"])
                .then((success) => {
                  console.log("publish homepage", success);
                })
                .catch((error) => {
                  console.error("Homepage Build Error", error);
                });
            })
            .catch((e) => {
              console.error("Problem saving homepage takeover", e);
              this.$store.commit("completeLoading");
            });
        });
      }
    },

    clearHomepageTakeover() {
      // console.log("cleared");
      this.saveHomepageTakeover("clearData");
    },
  },
};
</script>

<style lang="scss" scoped>
.homepage-takeover {
  position: relative;
  margin: 40px 0;
  padding: 16px 16px 0;
  border: 1px solid #67247c;
  border-radius: 6px;

  &__heading {
    position: absolute;
    top: -15px;
    left: -1px;
    display: inline;
    margin: 0;
    padding: 1px 5px;
    color: #fff;
    font-weight: 500;
    background-color: #67247c;
    border-radius: 0 4px 0 0;
  }

  &__alert {
    margin: 8px auto 16px;
  }
}

.align-bottom {
  align-items: center;
}

.match-details {
  padding-bottom: 16px;

  &__columns {
    justify-content: space-between;
  }

  &__column {
    max-width: calc(50% - 40px);
  }

  &__date {
    margin-bottom: 6px;
    font-size: 18px;
  }

  &__team-name {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
