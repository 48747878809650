<template>
    <v-list-tile :to="link">
        <v-list-tile-action>
            <v-icon>{{ icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
            <v-list-tile-title class="list-title">{{ label }}
                <span
                    v-if="restriction || hasRestriction
                        "
                    class="dev-label"
                >DEV</span></v-list-tile-title>
        </v-list-tile-content>
    </v-list-tile>
</template>

<script>
export default {
    name: "AppNavigationItem",

    props: {
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        restriction: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        hasRestriction: {
            get() {
                let isRestricted = false
                if (Array.isArray(this.restriction)) {
                    if (this.restriction && this.restriction.includes && this.restriction.includes('dev')) {
                        isRestricted = true
                    }
                }
                return isRestricted
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.theme--light {

    .v-list__tile__title,
    .v-icon {
        color: #94979b;
    }
}

.list-title {
    // display: flex;
    // align-items: center;
}

.dev-label {
    border: 1px solid #94979b;
    font-size: 10px;
    border-radius: 4px;
    padding: 2px 4px;
    margin-left: 6px;
    position: relative;
    bottom: 2px;
}
</style>
