
const Feature = require("../../../plugins/featureTemplate.js")

// For documentation purposes, these are the permissions a club can have
// const featureOptions = {
//     adServers: ['simple', 'adition', 'gam'] // At least one adServer is required
//     adSizes: ['mpu', 'banner', 'banner-large'] // At least one adSize is required
// }

const adServers = [
    {
        name: "Simple Ad",
        value: "simple",
    },
    {
        name: "Adition Ad Tag",
        value: "adition",
    },
    {
        name: "GAM",
        value: "gam",
    }
]

const adSizes = [
    {
        name: "MPU - 300x250",
        value: "mpu",
    },
    {
        name: "Banner - 728x90",
        value: "banner",
    },
    {
        name: "Large Banner - 1000x100",
        value: "banner-large",
    },
]


export default class AdvertisingFeature extends Feature.default {
    constructor(properties) {
        super(properties)
    }

    buildProperties(properties) {
        this.properties = {
            adServers: adServers.filter(adServer => properties.adServers && properties.adServers.includes(adServer.value)),
            adSizes: adSizes.filter(adSizes => properties.adSizes && properties.adSizes.includes(adSizes.value))
        }
    }

    get hasMultipleProviders() {
        if (this.properties && this.properties.adServers && this.properties.adServers.length && this.properties.adServers.length > 1) {
            return true
        } else {
            return false
        }
    }
}
