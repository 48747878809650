<template>
    <section class="app-detail">
        <div class="app-detail__version-notes">
            <div class="version">
                v.{{ version }}
                <!-- <span v-if="buildNumber"> / build.{{buildNumber}}</span> -->
            </div>
            <button
                @click="$emit('displayReleaseNotes')"
                class="app-detail__version-button"
            >Release Notes</button>
        </div>
    </section>

</template>

<script>
import { version } from "../../package.json";
export default {
    name: "AppInfo",

    data: () => ({
        version: version,
        buildNumber: `${process.env.VUE_APP_BUILD_NO}`,
        issueLink: process.env.VUE_APP_RAISE_ISSUE
    }),

    methods: {
        raiseIssue(destination) {
            window.open(destination)
        }
    }
}
</script>

<style
    scoped
    lang='scss'
>
.app-detail {
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: 0;
    left: 0;
    z-index: 3;
    padding: 8px 8px 8px 16px;
    width: 300px;
    background-color: rgba(47, 2, 61, 0.8);
    box-shadow: 0px -1px #94979B;

    &__version-notes {
        font-size: 12px;
        color: #94979B;
        margin: auto 0 auto auto;
        text-align: right;
    }

    &__version-button {
        background-color: #67247C;
        color: white;
        border-radius: 4px;
        padding: 2px 4px;
        margin-top: 4px;

        &:focus {
            outline: none;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 auto;
        text-align: right;

        &--link {
            color: #94979B;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        &--button {
            margin: 0 0 8px;
            font-size: 12px;
        }
    }
}
</style>
