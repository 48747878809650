class Feature {
    #properties

    constructor(properties) {
        this.rawProperties = properties
        this.buildProperties(properties)
    }

    buildProperties(properties) {
        this.properties = properties
    }
    
    getProperties(property) {
        return this.properties[property]
    }
}

export default Feature