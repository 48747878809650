<template>
  <section v-if="imageData" class="media-details">
    <div class="media-details__main">
      <div v-if="imageData.fileType === 'application/pdf'" class="media-details__image media-details__image--pdf">
      </div>
      <img v-else class="media-details__image" :src="`${imageURL}/${imageData.key}`" />
      <div class="media-details__body">
        <label :for="`filename-edit`" class="media-details__label">Filename</label>
        <input :name="`filename-edit`" v-model="imageData.name" readonly class="media-details__input" />

        <label :for="`image-src`" class="media-details__label">Image src</label>
        <input :name="`image-src`" :value="`${imageURL}/${imageData.key}`" readonly class="media-details__input" />

        <label :for="`alt-edit`" class="media-details__label">Alt Tag</label>
        <input :name="`alt-edit`" v-model="imageData.altTag" readonly class="media-details__input" />

        <label :for="`alt-caption`" class="media-details__label">Caption</label>
        <input :name="`alt-caption`" v-model="imageData.description" readonly class="media-details__input" />
      </div>
    </div>

    <div class="media-details__actions">
      <v-btn @click="closeDetails()">Back</v-btn>

      <!-- <v-btn color="accent">Update image</v-btn> -->
    </div>
  </section>
</template>

<script>
import axios from "axios"

export default {
  name: "ImageEditor",

  data:() => ({
    imageURL: process.env.VUE_APP_IMAGEURL,
    imageData: null
  }),

  mounted() {
    this.fetchImage(this.$store.state.mediaLibrary.editImage)
  },

  methods: {
    fetchImage(mediaLibraryID) {
      console.log("Fetch ", mediaLibraryID);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/media/${mediaLibraryID}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch image response:", response);

          this.imageData = response.data.body;
        })
        .catch(e => {
          console.error("Problem fetching image", e);
        });
      })
    },

    closeDetails() {
      console.log("Close image details")

      this.$store.commit("editImage", null);
    }
  }
}
</script>

<style scoped lang="scss">
  .media-details {
    position: fixed;
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px;
    z-index: 1000;
    overflow-y: scroll;
    background: #fff;
    box-shadow: 0 0 0px 16px rgba(0,0,0,0.5);

    &__main {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 16px;
      width: 100%;
    }

    &__image {
      width: calc(100% - 250px);

      &--pdf {
        background: transparent url('../../../assets/file.svg') no-repeat left top;
        height: 120px;
      }
    }

    &__body {
      margin: 0 0 0 20px;
      min-width: 230px;
      max-width: 230px;
    }

    &__input {
      padding: 7px 8px 6px 8px;
      margin: 0 0 16px;
      width: 100%;
      color: #24002f;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-style: italic;
      background-color: #fafafa;
      border-radius: 2px;
      border: solid 1px #dfe3e9;
    }

    &__label {
      display: block;
      margin: 0 0 3px;
      padding: 0;
      color: #7b6b80;
      font-family: 'Rubik', sans-serif;
      font-size: 12px;
      line-height: 1;
    }

    &__actions {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      height: 70px;
      padding: 0 26px 0 160px;
      margin: 16px 0 0 16px;
      z-index: 10;
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);

      &__label {
        margin: 0 16px 0 0;
        padding: 0;
        color: #24002f;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-align: left;

        span {
          font-weight: 400;
        }

        &--error {
          color: #f42323;
        }
      }

      &__folder {
        padding: 0;
        margin: 0 16px;
        opacity: 0.5;
        color: #516173;
        font-family: 'Rubik', sans-serif;
        font-size: 16px;
        font-weight: 500;
      }

      &__folder-icon {
        position: relative;
        top: 2px;
        padding: 0 4px 0 0;
      }

      &__location {
        margin: 0 0 10px;
        padding: 0;
        color: #516173;
        font-family: 'Rubik', sans-serif;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        opacity: 0.5;
      }

      &__location-folder {
        border-bottom: 1px solid;
        padding: 3px;
        border-radius: 4px;
      }

      &__location-icon {
        padding: 0 3px 0 0;
        color: #516173;
        font-size: 16px;
      }   
  
    }
  }
</style>
