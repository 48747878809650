import axios from "axios"

const publishPath = process.env.VUE_APP_PAGEAPI
const gcPublisher = {

  install(Vue) {
    console.log(`gcPublisher ${publishPath} initialised`)

    /*
    @args
      - pages: Array
    */

    Vue.prototype.$gcPublish = function(pages) {
      return new Promise((resolve, reject) => {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: publishPath,
            headers: { "Authorization": jwt },
            data: {
              "pages": pages
            }
          })
          .then(response => {
            console.log("Build triggered:", response);
            resolve("buildSent", response)
          })
          .catch(e => {
            console.error("Problem triggering build", e);
            reject({
              message: e
            });
          });
        })
      })
    }
  }
}

export default gcPublisher
