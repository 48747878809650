<template>
  <div class="single-stat">
    <h4 class="single-stat__title">{{ title }}</h4>
    <p class="single-stat__value">{{ value }}</p>
  </div>
</template>

<script>
export default {
  name: "SingleStat",

  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .single-stat {
    padding: 16px;
    color: #fff;
    background: linear-gradient(45deg, #24002F, #67247c);

    &__title {
      font-size: 14px;
      font-weight: 300;
    }

    &__value {
      margin: 0;
      padding: 0;
      font-size: 50px;
      font-weight: 500;
    }
  }
</style>
