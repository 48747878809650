import { render, staticRenderFns } from "./FixtureDetails.vue?vue&type=template&id=291c5c7c&scoped=true"
import script from "./FixtureDetails.vue?vue&type=script&lang=js"
export * from "./FixtureDetails.vue?vue&type=script&lang=js"
import style0 from "./FixtureDetails.vue?vue&type=style&index=0&id=291c5c7c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "291c5c7c",
  null
  
)

export default component.exports