<template>
    <v-navigation-drawer
        v-model="sidebar"
        fixed
        app
        :mobile-break-point="this.$vuetify.breakpoint.thresholds.lg"
    >
        <v-img
            :src="require('../assets/gamechanger-logo-white.svg')"
            class="logo"
            contain
            height="100"
        ></v-img>

        <v-list class="sidebar">
            <template v-if="featureConfig.default.Dashboard">
                <AppNavigationItem
                    label="Dashboard"
                    icon="dashboard"
                    link="/"
                />
            </template>
            <template v-if="featureConfig.default.News">
                <AppNavigationItem
                    label="News"
                    icon="local_library"
                    link="/news"
                />
            </template>
            <template v-if="featureConfig.default.Pages">
                <AppNavigationItem
                    label="Pages"
                    icon="insert_drive_file"
                    link="/pages"
                />
            </template>
            <template v-if="featureConfig.default.AggregatedContentFeed">
                <AppNavigationItem
                    label="Content Feed"
                    icon="view_compact"
                    link="/content-feed"
                />
            </template>
            <template v-if="featureConfig.default.Blog">
                <AppNavigationItem
                    label="Blog"
                    icon="feed"
                    link="/blog"
                />
            </template>
            <template v-if="featureConfig.default.Broadcasters">
                <AppNavigationItem
                    label="Broadcasters"
                    icon="podcasts"
                    link="/broadcasters"
                />
            </template>
            <template v-if="featureConfig.default.Fixtures || featureConfig.default.Fixtures">
                <AppNavigationItem
                    label="Team Management"
                    icon="sports_soccer"
                    link="/team-management"
                />
            </template>
            <template v-if="featureConfig.default.Calendar">
                <AppNavigationItem
                    label="Calendar"
                    icon="calendar_today"
                    link="/calendar"
                />
            </template>
            <!-- <template v-if="featureConfig.default.Fixtures">
        <AppNavigationItem label="Fixtures" icon="calendar_today" link="/fixtures" />
      </template>
      <template v-if="featureConfig.default.Squads">
         <AppNavigationItem label="Squads" icon="group" link="/squads" />
      </template> -->
            <template v-if="featureConfig.default.Organisations">
                <AppNavigationItem
                    label="Organisations"
                    icon="add_location_alt"
                    link="/organisations"
                />
            </template>
            <template v-if="featureConfig.default.MapDirectory">
                <AppNavigationItem
                    label="Map Directory"
                    icon="add_location_alt"
                    link="/map-directory"
                />
            </template>
            <template v-if="featureConfig.default.Docs">
                <AppNavigationItem
                    label="Documentation"
                    icon="description"
                    link="/documents"
                />
            </template>
            <template v-if="featureConfig.default.Forms">
                <AppNavigationItem
                    label="Forms"
                    icon="assignment"
                    link="/forms"
                />
            </template>
            <template v-if="featureConfig.default.Judo">
                <AppNavigationItem
                    label="Judo"
                    icon="touch_app"
                    link="/judo"
                />
            </template>
            <template v-if="featureConfig.default.Videos">
                <AppNavigationItem
                    v-if="featureConfig.default.Streamline"
                    label="Streamline/Videos"
                    icon="ondemand_video"
                    link="/video-management"
                />
                <AppNavigationItem
                    v-else
                    label="Videos"
                    icon="ondemand_video"
                    link="/video-management"
                />
            </template>
            <template v-if="featureConfig.default.Media">
                <AppNavigationItem
                    label="Media"
                    icon="wallpaper"
                    link="/media"
                />
            </template>
            <template v-if="featureConfig.default.OverlayPromos">
                <AppNavigationItem
                    label="Overlay Promos"
                    icon="vignette"
                    link="/overlay-promos"
                />
            </template>
            <template v-if="featureConfig.default.Advertising || permissions.Advertising2">
                <AppNavigationItem
                    label="Advertising"
                    icon="picture_in_picture_alt"
                    link="/advertising"
                    :restriction="(restrictedPermissions.Advertising &&
                        restrictedPermissions.Advertising.length > 0 &&
                        restrictedPermissions.Advertising) ||
                        (restrictedPermissions.Advertising2 &&
                            restrictedPermissions.Advertising2.length > 0 &&
                            restrictedPermissions.Advertising2)
                        "
                />
            </template>
            <template v-if="featureConfig.default.Lab">
                <AppNavigationItem
                    label="[Alpha] Advertising"
                    icon="science"
                    link="/lab"
                />
            </template>
            <template v-if="featureConfig.default.Sponsors">
                <AppNavigationItem
                    label="Sponsors"
                    icon="picture_in_picture"
                    link="/sponsors"
                />
            </template>
            <template v-if="featureConfig.default.SiteConfig">
                <AppNavigationItem
                    label="Website Manager"
                    icon="web_asset"
                    link="/website-management"
                />
            </template>
            <template v-if="featureConfig.default.AppConfig">
                <AppNavigationItem
                    label="App Manager"
                    icon="app_settings_alt"
                    link="/app-management"
                />
            </template>
            <template v-if="featureConfig.default.Settings">
                <AppNavigationItem
                    label="Global Settings"
                    icon="settings"
                    link="/global-settings"
                />
            </template>
            <template v-if="featureConfig.default.SsoUsers || featureConfig.default.Streamline">
                <AppNavigationItem
                    label="User Management"
                    icon="person_search"
                    link="/users"
                />
            </template>
            <template v-if="featureConfig.default.Profile">
                <AppNavigationItem
                    label="My Profile"
                    icon="manage_accounts"
                    link="/profile"
                />
            </template>
            <template v-if="featureConfig.default.Admin">
                <AppNavigationItem
                    label="Admin Users"
                    icon="admin_panel_settings"
                    link="/admin-users"
                />
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import AppNavigationItem from "@/components/AppNavigationItem";

export default {
    name: "AppNavigation",

    components: {
        AppNavigationItem,
    },

    computed: {
        sidebar: {
            get() {
                return this.$store.state.sidebar;
            },
            set(val) {
                this.$store.commit("toggleSidebar", val);
            },
        },
    },

    data() {
        return {
            permissions: {}, // Eventually all permissions can be migrated to use this variable instead of the below
            restrictedPermissions: {},
            featureConfig: null,
            clientID: `${process.env.VUE_APP_CLUB_ID}`,
        };
    },

    created() {
        this.featureConfig = require("../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");

        this.getPermissions();
    },

    methods: {
        getPermissions() {
            if (!this.$store.state.user.email) {
                setTimeout(() => {
                    this.getPermissions();
                }, 1000);
            } else {
                if (!this.$gcPermissions().user) {
                    this.$gcPermissions().defineUser(this.$store.state.user.email);
                }

                this.permissions = this.$gcPermissions().getUserPermissions();
                this.restrictedPermissions =
                    this.$gcPermissions().getRestrictedPermissions();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.sidebar {
    padding-bottom: 96px;
}

.theme--light {
    &.v-navigation-drawer {
        color: #94979b;
        background-color: #24002f;
        padding-bottom: 96px;
    }
}

.logo {
    margin: 0 30px;
}
</style>
