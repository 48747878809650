import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      firstName: "",
      lastName: "",
      email: ""
    },
    club: {
      name: process.env.VUE_APP_CLUB_NAME
    },
    loading: false,
    sidebar: true,
    insights: {
      selectedPlayers: {}
    },
    currentMediaDirectory: "/",
    mediaLibrary: {
      directoryName: "Root",
      directoryID: "root",
      subdirectories: [],
      activeTree: [],
      editImage: null,
      selectedImage: {
        imageKey: "",
        mediaLibraryID: ""
      },
      multiSelectedImages: []
    }
  },
  mutations: {
    toggleSidebar(state, val) {
      state.sidebar = val;
    },
    openSidebar(state) {
      state.sidebar = true
    },
    closeSidebar(state) {
      state.sidebar = false
    },
    startLoading(state) {
      state.loading = true
    },
    completeLoading(state) {
      state.loading = false
    },
    saveUserDetails(state, userDetails) {
      state.user.firstName = userDetails.firstName
      state.user.lastName = userDetails.lastName
      state.user.email = userDetails.email
    },
    saveCurrentMediaDirectory(state, val){
      state.currentMediaDirectory = val
    },
    savePlayerInsights(state, selectedPlayers) {
      state.insights.selectedPlayers = selectedPlayers
    },
    storeDirectoryDetails(state, directory) {
      state.mediaLibrary.directoryName = directory.directoryName
      state.mediaLibrary.directoryID = directory.directoryID
    },
    storeActiveTree(state, activeTree) {
      state.mediaLibrary.activeTree = activeTree
    },
    editImage(state, mediaLibraryID) {
      state.mediaLibrary.editImage = mediaLibraryID
    },
    selectImage(state, selectedImage) {
      state.mediaLibrary.selectedImage.mediaLibraryID = selectedImage.mediaLibraryID
      state.mediaLibrary.selectedImage.imageKey = selectedImage.imageKey
    },
    removeImage(state) {
      state.mediaLibrary.selectedImage.mediaLibraryID = ""
      state.mediaLibrary.selectedImage.imageKey = ""
    },
    multiSelectImage(state, mediaLibraryID) {
      if (!state.mediaLibrary.multiSelectedImages.includes(mediaLibraryID)) {
        state.mediaLibrary.multiSelectedImages.push(mediaLibraryID)
      } else {
        const indexOfItem = state.mediaLibrary.multiSelectedImages.indexOf(mediaLibraryID)
        state.mediaLibrary.multiSelectedImages.splice(indexOfItem, 1)
      }
    },
    multiSelectImageClearAll(state) {
      state.mediaLibrary.multiSelectedImages = []
    }
  },
  actions: {

  }
})
