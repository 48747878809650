var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{staticClass:"version-history"},[_vm._l((_vm.versionHistory),function(version){return _c('v-expansion-panel-content',{key:version.versionNumber,staticClass:"version-history__accordion-item"},[_c('div',{class:[
            'version-history__accordion-header',
            {
                'version-history__accordion-header--new': _vm.checkIfNew(
                    version.versionNumber
                ),
            },
            {
                'version-history__accordion-header--coming-soon': _vm.checkIfUpcoming(
                    version.versionNumber
                ),
            },
        ],attrs:{"slot":"header"},slot:"header"},[_vm._v(" v "+_vm._s(version.versionNumber)+" ")]),_c('v-card',[_c('v-card-text',{staticClass:"wysiwyg",domProps:{"innerHTML":_vm._s(version.versionDetails)}})],1)],1)}),_c('span',{staticClass:"version-history__contact"},[_vm._v("If you believe you're missing a feature listed here, or would like to discuss a feature please raise a ticket in Freshdesk using the help button in the bottom left of the window.")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }