import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

import { Observable } from "rxjs/Observable";
import jwtDecode from "jwt-decode";
import jose from "node-jose";
import https from "https";

const CognitoPlugin = {
  install(Vue) {
    var secureFlag = false; // dictates if we use secure cookies or not
    secureFlag = process.env.VUE_APP_COOKIE_SECURE === "true";
    if (secureFlag === true) {
      // console.log('Cookie secure flag set to TRUE')
    } else {
      // console.log("Cookie secure flag set to FALSE");
    }
    const poolData = {
      UserPoolId: process.env.VUE_APP_AWS_IDENTITY_POOL,
      ClientId: process.env.VUE_APP_AWS_CLIENT_ID,
    };

    console.log("Domain" + process.env.VUE_APP_DOMAIN);

    const userPool = new CognitoUserPool(poolData);

    Vue.mixin({
      mounted() {
        // console.log('Cognito mixin loaded')
      },
    });

    Vue.prototype.$isLoggedIn = function () {
      return userPool.getCurrentUser() != null;
    };

    Vue.prototype.$getAuthenticatedUser = function () {
      // gets the current user from the local storage
      return userPool.getCurrentUser();
    };

    Vue.prototype.$authenticateUser = function (
      email,
      password,
      newPassword = null,
      addAttributes = null
    ) {
      const authenticationData = {
        Username: email.toLowerCase(),
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );

      const userData = {
        Username: email.toLowerCase(),
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      // console.log(authenticationData)
      return Observable.create((observer) => {
        console.log("inside observer");
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: function (result) {
            // console.log('LOGIN RESULT' + JSON.stringify(result))
            observer.next(result);
            observer.complete();
          },
          onFailure: function (err) {
            console.log(err);
            console.log(err.code);
            observer.error(err);
          },
          newPasswordRequired: function (userAttributes, requiredAttributes) {
            console.log("New password required" + requiredAttributes);
            if (newPassword) {
              delete userAttributes.email_verified;
              delete userAttributes.phone_number_verified;
              delete userAttributes.email;
              if (addAttributes) {
                userAttributes["given_name"] = addAttributes.given_name;
                userAttributes["family_name"] = addAttributes.family_name;
              }
              console.log(userAttributes);
              console.log("Setting new password");
              cognitoUser.completeNewPasswordChallenge(
                newPassword,
                userAttributes,
                this
              );
            } else {
              console.log(
                "New password required but a new password has not been passed"
              );
              var err = {
                code: "newPasswordRequired",
                message: "User must set a new password",
              };
              observer.error(err);
            }
          },
          totpRequired: function () {
            observer.next({checkMfa: true, cognitoUser})
            observer.complete()
          }
        })
      })
    }

    Vue.prototype.$sendMFA = function (challengeAnswer, cognitoUser) {
      return Observable.create(observer => {
        cognitoUser.sendMFACode(challengeAnswer, {
          onSuccess: function (result) {
            observer.next(result)
            observer.complete()
          },
          onFailure: function (err) {
            console.log(err)
            console.log(err.code)
            observer.error(err)
          }
        }, "SOFTWARE_TOKEN_MFA")
      })
    }

    Vue.prototype.$signUp = function (email, password, attributeList = []) {
      const attributes = [];

      if (attributeList) {
        Object.entries(attributeList).forEach((entry) => {
          attributes.push(
            new CognitoUserAttribute({
              Name: entry[0],
              Value: entry[1],
            })
          );
        });
      }
      // attributes.push(
      //  new CognitoUserAttribute({
      //    Name: 'email',
      //    Value: email.toLowerCase()
      //  })
      // )

      return Observable.create((observer) => {
        userPool.signUp(
          email.toLowerCase(),
          password,
          attributes,
          null,
          (err, result) => {
            if (err) {
              console.log("signUp error", err);
              observer.error(err);
            }
            console.log("signUp success", result);
            observer.next(result);
            observer.complete();
          }
        );
      });
    };

    Vue.prototype.$confirmRegistration = function (email, code) {
      const user = {
        Username: email.toLowerCase(),
        Pool: userPool,
      };
      return Observable.create((observer) => {
        const cognitoUser = new CognitoUser(user);
        cognitoUser.confirmRegistration(code, true, function (err, result) {
          if (err) {
            console.log(err);
            observer.error(err);
          }
          // console.log("confirmAuthCode() success", result);
          observer.next(result);
          observer.complete();
        });
      });
    };

    Vue.prototype.$resendConfirmationCode = function (email) {
      const user = {
        Username: email.toLowerCase(),
        Pool: userPool,
      };
      return Observable.create((observer) => {
        const cognitoUser = new CognitoUser(user);
        cognitoUser.resendConfirmationCode(function (err, result) {
          if (err) {
            console.log(err);
            observer.error(err);
          }
          // console.log("resendConfirmationCode() success", result);
          observer.next(result);
          observer.complete();
        });
      });
    };

    Vue.prototype.$forgotPassword = function (email) {
      const user = {
        Username: email.toLowerCase(),
        Pool: userPool,
      };
      return Observable.create((observer) => {
        const cognitoUser = new CognitoUser(user);
        cognitoUser.forgotPassword({
          onSuccess() {
            // console.log("Password reset email sent");
            observer.next();
            observer.complete();
          },
          onFailure(err) {
            // console.log("Password reset email not sent");
            observer.error(err);
          },
        });
      });
    };

    Vue.prototype.$confirmPassword = function (email, code, password) {
      const user = {
        Username: email.toLowerCase(),
        Pool: userPool,
      };
      return Observable.create((observer) => {
        const cognitoUser = new CognitoUser(user);
        cognitoUser.confirmPassword(code, password, {
          onSuccess() {
            // console.log("Password reset confirmed!");
            observer.next();
            observer.complete();
          },
          onFailure(err) {
            // console.log("Password reset not confirmed!");
            observer.error(err);
          },
        });
      });
    };

    Vue.prototype.$getSession = function () {
      return Observable.create((observer) => {
        var cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function (err, session) {
          if (err) {
            observer.error(err);
          }
          observer.next(session);
          observer.complete();
        });
      });
    };

    Vue.prototype.$getCurrentUserGroups = function () {
      return Observable.create((observer) => {
        var cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function (err, session) {
          if (err) {
            observer.error(err);
          }
          var sessionIdInfo = jwtDecode(session.getIdToken().jwtToken);
          observer.next(sessionIdInfo["cognito:groups"]);
          observer.complete();
        });
      });
    };

    Vue.prototype.$getCurrentUserJwToken = function () {
      return Observable.create((observer) => {
        var cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function (err, session) {
          if (err) {
            observer.error(err);
          }
          observer.next(session.getIdToken().jwtToken);
          observer.complete();
        });
      });
    };

    // Retrieves the attributes of the current user
    Vue.prototype.$getCurrentUserAttributes = function () {
      var cognitoUser = userPool.getCurrentUser();
      return Observable.create((observer) => {
        if (cognitoUser != null) {
          cognitoUser.getSession(function (err, session) {
            if (err) {
              // console.log(err.message || JSON.stringify(err));
              observer.error(err);
            }
            // console.log("session validity: " + session.isValid());
            // NOTE: getSession must be called to authenticate user before calling getUserAttributes
            cognitoUser.getUserAttributes(function (err, attributes) {
              if (err) {
                console.log(err);
                observer.error(err);
              } else {
                // Do something with attributes
                var returnAttributes = {};

                attributes.forEach(function (attribute) {
                  returnAttributes[attribute.Name] = attribute.Value;
                });

                observer.next(returnAttributes);
                observer.complete();
              }
            });
          });
        }
      });
    };

    Vue.prototype.$updateAttributes = function (attributeList = []) {
      const attributes = [];
      var cognitoUser = userPool.getCurrentUser();

      if (attributeList) {
        Object.entries(attributeList).forEach((entry) => {
          if (entry[0] === "email") {
            entry[1] = entry[1].toLowerCase();
          }
          attributes.push(
            new CognitoUserAttribute({
              Name: entry[0],
              Value: entry[1],
            })
          );
        });
      }
      return Observable.create((observer) => {
        if (cognitoUser != null) {
          cognitoUser.getSession(function (err, session) {
            if (err) {
              console.log(err.message || JSON.stringify(err));
              observer.error(err);
            }
            // console.log("session validity: " + session.isValid());
            cognitoUser.updateAttributes(attributes, (err, result) => {
              if (err) {
                console.log("update error", err);
                observer.error(err);
              }
              var response = {
                message: "Your details have been successfully updated",
              };
              console.log("update success", result);
              observer.next(response);
              observer.complete();
            });
          });
        } else {
          observer.error("No active user");
        }
      });
    };

    Vue.prototype.$changePassword = function (oldPassword, newPassword) {
      var cognitoUser = userPool.getCurrentUser();
      return Observable.create((observer) => {
        if (cognitoUser != null) {
          cognitoUser.getSession(function (err, session) {
            if (err) {
              console.log(err.message || JSON.stringify(err));
              observer.error(err);
            }
            // console.log("session validity: " + session.isValid());
            cognitoUser.changePassword(
              oldPassword,
              newPassword,
              function (err, result) {
                if (err) {
                  console.log(err.message || JSON.stringify(err));
                  observer.error(err);
                } else {
                  var response = {
                    message: "Your password has been successfully updated",
                  };
                  observer.next(response);
                  observer.complete();
                }
              }
            );
          });
        } else {
          observer.error("No active user");
        }
      });
    };
    Vue.prototype.$signOut = function () {
      // sign out from user pool
      this.$getAuthenticatedUser().signOut();
    };

    Vue.prototype.$getUserSubFromIdToken = function (token) {
      var sections = token.split(".");
      // get the kid from the headers prior to verification
      var body = jose.util.base64url.decode(sections[1]);
      body = JSON.parse(body);
      return body.sub;
    };

    Vue.prototype.$getUserFieldFromIdToken = function (field, token) {
      var sections = token.split(".");
      // get the kid from the headers prior to verification
      var body = jose.util.base64url.decode(sections[1]);
      body = JSON.parse(body);
      if (body[field]) {
        return body[field];
      } else {
        return null;
      }
    };

    // Verify a JWT token
    // Inspired by https://github.com/awslabs/aws-support-tools/blob/master/Cognito/decode-verify-jwt/decode-verify-jwt.js)
    Vue.prototype.$verifyToken = function (token) {
      var keysUrl =
        "https://cognito-idp." +
        process.env.VUE_APP_AWS_REGION +
        ".amazonaws.com/" +
        process.env.VUE_APP_AWS_IDENTITY_POOL +
        "/.well-known/jwks.json";
      var sections = token.split(".");
      // get the kid from the headers prior to verification
      var header = jose.util.base64url.decode(sections[0]);
      header = JSON.parse(header);
      var kid = header.kid;
      console.log("kid = " + kid);
      // download the public keys
      return Observable.create((observer) => {
        https.get(keysUrl, function (response) {
          console.log(response);
          if (response.statusCode === 200) {
            console.log("valid response from server");
            response.on("data", function (body) {
              var keys = JSON.parse(body)["keys"];
              // search for the kid in the downloaded public keys
              var keyIndex = -1;
              var i = 0;
              for (i; i < keys.length; i++) {
                if (kid === keys[i].kid) {
                  keyIndex = i;
                  break;
                }
              }
              if (keyIndex === -1) {
                console.log("Public key not found in jwks.json");
                observer.error("Public key not found in jwks.json");
              }
              console.log("keyIndex = " + keyIndex);
              // construct the public key
              jose.JWK.asKey(keys[keyIndex]).then(function (result) {
                // verify the signature
                jose.JWS.createVerify(result)
                  .verify(token)
                  .then(function (result) {
                    // now we can use the claims
                    var claims = JSON.parse(result.payload);
                    console.log("claims = ", claims);
                    // additionally we can verify the token expiration
                    var currentTs = Math.floor(new Date() / 1000);
                    if (currentTs > claims.exp) {
                      console.log("Token is expired");
                      observer.error("Token is expired");
                    }
                    // and the Audience (use claims.client_id if verifying an access token)
                    if (
                      claims.client_id !== process.env.VUE_APP_AWS_CLIENT_ID &&
                      claims.aud !== process.env.VUE_APP_AWS_CLIENT_ID
                    ) {
                      console.log("Token was not issued for this audience ");
                      observer.error("Token was not issued for this audience");
                    }
                    observer.next();
                    observer.complete(claims);
                  })
                  .catch(function () {
                    console.log("Signature verification failed");
                    observer.error("Signature verification failed");
                  });
              });
            });
          } else {
            console.log("did not receive a 200 response");
          }
        });
      });
    };
  },
};
export default CognitoPlugin;
