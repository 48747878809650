<template>
    <section class="main-body">
        <h1>Hi, {{ $store.state.user.firstName }}</h1>
        <p>
            Welcome to Gamechanger
            <template v-if="clubName">for {{ clubName }}</template>
        </p>

        <p
            v-if="mfaWarning && featureConfig.default.MFA"
            class="warning-text"
        >
            Your account does not have Multi-Factor Authentication enabled. Please go
            to <span @click="$router.push('/profile')">"My Profile"</span> to enable
            it
        </p>

        <self-help
            location="dashboard"
            title="Get up to speed"
        />

        <template v-if="loadStats">
            <div class="double-stats">
                <single-stat
                    class="double-stats__first-item"
                    :title="`Current Users on ${siteDomain}`"
                    :value="currentUserCount"
                />

                <top-pages-list-stat
                    class="double-stats__second-item"
                    :title="'Top active pages'"
                    :values="topPages"
                />
            </div>

            <p class="fathom-notice">Stats powered by Fathom</p>
        </template>

        <template v-if="betaFeatures.default.MatchdayTakeover">
            <matchday-takeover-v2 />
        </template>

        <template v-if="featureConfig.default.Fixtures">
            <div
                class="next-match"
                v-if="nextMatch"
            >
                <p class="next-match__title">Next Match</p>
                <div class="next-match__info">
                    <p class="next-match__teams">
                        <template v-if="nextMatch.teamData[0].shortTeamName">
                            {{ nextMatch.teamData[0].shortTeamName }}
                        </template>
                        <template v-else>
                            {{ nextMatch.teamData[0].teamName }}
                        </template>
                        -
                        <template v-if="nextMatch.teamData[1].shortTeamName">
                            {{ nextMatch.teamData[1].shortTeamName }}
                        </template>
                        <template v-else>
                            {{ nextMatch.teamData[1].teamName }}
                        </template>
                        @ {{ nextMatch.venue }}
                    </p>
                    <p class="next-match__kickoff">
                        Kickoff: {{ nextMatch.kickOff | moment("DD MMM YYYY - HH:mm") }}
                    </p>
                    <p class="next-match__id">Match ID: {{ nextMatchID }}</p>
                </div>

                <v-btn
                    color="accent"
                    outline
                    flat
                    @click="gotoFixture(nextMatchID)"
                >
                    Edit fixture
                </v-btn>
            </div>

            <div
                v-else
                class="next-match"
            >
                <p class="next-match__title">Next Match</p>

                <div class="next-match__info">
                    <p>No upcoming matches</p>
                </div>
            </div>
        </template>

        <template v-if="featureConfig.default.MatchdayTakeover">
            <MatchdayTakeover
                v-if="teamID"
                :teamID="teamID"
            />
        </template>
    </section>
</template>

<script>
import axios from "axios";
import MatchdayTakeover from "@/components/MatchdayTakeover/MatchdayTakeover";
import MatchdayTakeoverV2 from "@/components/MatchdayTakeover/MatchdayTakeoverV2";
import SelfHelp from "@/components/Library/SelfHelp";
import SingleStat from "@/components/Library/StatBlocks/SingleStat";
import TopPagesListStat from "@/components/Library/StatBlocks/TopPagesListStat.vue";

export default {
    name: "Dashboard",

    components: {
        MatchdayTakeover,
        MatchdayTakeoverV2,
        SelfHelp,
        SingleStat,
        TopPagesListStat,
    },

    data: () => ({
        teamID: null,
        nextMatch: null,
        nextMatchID: null,
        fixturesConfig: [],
        rebuildLock: false,
        featureConfig: null,
        betaFeatures: null,
        siteDomain: process.env.VUE_APP_SITE_DOMAIN,
        loadStats: false,
        currentUserCount: null,
        topPages: [],
        adminApi: `${process.env.VUE_APP_BASEURL}/adminusers`,
        mfaWarning: false,
        defaultSettings: null,
    }),

    computed: {
        clubName: {
            get() {
                return this.$store.state.club.name;
            },
        },
    },

    created() {
        this.featureConfig = require("../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
        this.defaultSettings = require("../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/defaultSettings.js");
        this.betaFeatures = require("../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/beta.js");
    },

    mounted() {
        if (this.featureConfig.default.Fixtures) {
            this.fetchFixturesConfig();
        }

        if (this.siteDomain && this.featureConfig.default.WebStats) {
            this.fathomCurrentUsers();
        }

        if (!this.$store.state.user.email) {
            if (this.$getAuthenticatedUser() != null) {
                console.log("Access Check", "user authenticated");
                this.$getCurrentUserAttributes().subscribe((userData) => {
                    //check if the user has completed their registration (mostly this will be Social Media Registrations, post linking of their account)
                    console.log("Access Check", "checking registration is complete");
                    console.log("User data", userData);
                    this.$store.commit("saveUserDetails", {
                        firstName: userData.given_name,
                        lastName: userData.family_name,
                        email: userData.email,
                    });
                    this.$gcPermissions().defineUser(userData.email);
                    if (this.defaultSettings.default.MFARequired) {
                        this.checkMFAWarning();
                    }
                });
            }
        } else {
            this.checkMFAWarning();
        }
    },

    methods: {
        fathomCurrentUsers() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${process.env.VUE_APP_CLIENTSETTINGSAPI}/get?settingID=web`, {
                        headers: { Authorization: jwt },
                    })
                    .then((response) => {
                        if (response.data.body) {
                            if (response.data.body.settings.fathomID) {
                                axios
                                    .get(
                                        `${process.env.VUE_APP_FATHOMAPI}/fathom?site_id=${response.data.body.settings.fathomID}&detailed=true&path=/current_visitors`
                                    )
                                    .then((response) => {
                                        // console.log("Fathom: ", response);
                                        this.loadStats = true;
                                        this.currentUserCount = response.data.data.total;
                                        this.topPages = response.data.data.content.slice(0, 5);
                                    })
                                    .catch((error) => {
                                        console.error("Fathom error", error);
                                    });
                            }
                        }
                    })
                    .catch((e) => {
                        console.error("Problem retrieving configs", e);
                    });
            });
        },

        fetchFixturesConfig() {
            this.$store.commit("startLoading");
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`, {
                        headers: { Authorization: jwt },
                    })
                    .then((response) => {
                        // console.log("response: ", response);
                        this.teamID = response.data.body[0].teamID;

                        this.fetchNextMatch();
                    })
                    .catch((e) => {
                        console.error("Problem retrieving fixtures", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },

        fetchNextMatch() {
            this.$store.commit("startLoading");

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(
                        `${process.env.VUE_APP_WEBAPI}/fixtures/opta/getupcoming?homeOrAway=Both&numberOfMatches=1&teamID=${this.teamID}&seasonID=${process.env.VUE_APP_CURRENTSEASON}`,
                        { headers: { Authorization: jwt } }
                    )
                    .then((response) => {
                        // console.log('Next match: ', response);

                        if (response.data.body) {
                            this.nextMatchID = response.data.body[0].matchID;
                            this.nextMatch = response.data.body[0];
                        }
                        this.$store.commit("completeLoading");
                    })
                    .catch((e) => {
                        console.error("Problem retrieving next match", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },

        gotoFixture(matchID) {
            this.$router.push({
                name: "Fixture Editor",
                params: {
                    matchID: matchID,
                    teamID: this.teamID,
                    seasonID: process.env.VUE_APP_CURRENTSEASON,
                },
            });
        },

        checkMFAWarning() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.adminApi}/${this.$store.state.user.email}`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("retrieved user:", response);
                        const user = JSON.parse(response.data.body);
                        if (!user.UserMFASettingList) {
                            this.mfaWarning = true;
                        }
                    })
                    .catch((e) => {
                        console.error("Problem adding admin user", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem adding user";
                    });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.double-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__first-item {
        width: calc(33.33% - 16px);
    }

    &__second-item {
        width: calc(66.66% - 16px);
    }
}

.fathom-notice {
    margin: 8px 0 16px;
    padding: 0 0 16px;
    font-size: 10px;
    font-weight: 300;
    border-bottom: 1px solid #dfe3e9;
}

.next-match {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 0;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #67247c;
    border-radius: 4px;

    &__title {
        position: absolute;
        top: -15px;
        left: -1px;
        display: inline;
        margin: 0;
        padding: 1px 5px;
        color: #fff;
        font-weight: 500;
        background-color: #67247c;
        border-radius: 0 4px 0 0;
    }

    &__teams {
        padding: 0;
        margin: 0 0 10px;
        font-weight: 500;
    }

    &__kickoff {
        padding: 0;
        margin: 0;
        font-weight: 300;
    }

    &__id {
        margin: 8px 0 0;
        padding: 8px 0 0;
        font-family: monospace;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
}

.align-bottom {
    align-items: center;
}

.button--match-centre {
    opacity: 0.6;
}

.warning-text {
    background-color: #f4f6d2;
    padding: 8px 16px;
    border: 1px solid #d6d8b0;
    border-radius: 8px;

    span {
        cursor: pointer;
        text-decoration: underline;
    }
}
</style>
