export default {
  aggregatedFeed: {
    showAllNews: 1
  },
  allowUpsellInApp: false,
  pageTypes: [
    {value: "internal", text: "Website Page"},
    {value: "external", text: "External Page"},
    {value: "internalLink", text: "Internal Page Link"},
    // {value: 'homepage', text: 'Homepage'},
  ],
  platformSettings: {
    showWeb: true,
    showApp: true
  },
  defaultNewsCardStyle: "Stack",
  playerManagement: "V3",
  playerPageDefaultLayout: {
    pageStructure: {
      bio: "dark", // 'hide' | 'light' | 'dark' | 'club'
      stats: "show", // 'hide' | 'show'
      history: "light", // 'hide' | 'light' | 'dark' | 'club'
      awards: "hide", // 'hide' | 'light' | 'dark' | 'club'
      gallery: "hide", // 'hide' | 'light' | 'dark' | 'club'
      news: "dark", // 'hide' | 'light' | 'dark' | 'club'
      retail: "hide", // 'hide' | 'light' | 'dark' | 'club'
      video: null // 'hide' | 'light' | 'dark' | 'club'
    }
  },
  customDataFields: {
    customDataFieldOneName: "",
    customDataFieldOneDescription: "",
    customDataFieldTwoName: "",
    customDataFieldTwoDescription: "",
    customDataFieldThreeName: "",
    customDataFieldThreeDescription: "",
  },
  customTags: []
}