<template>
  <section class="match-picker">
    <v-select
      class="item"
      v-if="fixturesConfig"
      :items="restrictSubTeams ? fixturesConfig[0] : fixturesConfig"
      v-model="matchData.teamID"
      label="Select Team"
      item-value="teamID"
      item-text="teamName"
      @change="
        setSeasons(matchData.teamID);
        matchData.seasonID = null;
        matchData.matchDetails.matchID = null;
        matches = [];
      "
    >
    </v-select>

    <v-select
      class="item"
      :items="seasons"
      v-model="matchData.seasonID"
      label="Select Season"
      item-value="seasonID"
      item-text="seasonName"
      :disabled="matchData.teamID === null"
      @change="
        getFixtures();
        matchData.matchDetails.matchID = null;
        matches = [];
      "
    >
    </v-select>

    <v-select
      class="item item--match"
      :items="matches"
      item-value="matchID"
      item-text="matchLabel"
      v-model="matchData.matchDetails.matchID"
      label="Select Match"
      :disabled="matchData.seasonID === null"
      @change="updateMatch()"
    >
    </v-select>
    <p v-if="matchData.seasonID && !matchesLoaded">loading matches...</p>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "MatchPickerV2",

  props: {
    displayCustomFixtures: {
      type: Boolean,
      required: true,
    },
    restrictSubTeams: {
      type: Boolean,
      required: false,
    },
  },

  data: () => ({
    matchData: {
      teamID: null,
      seasonID: null,
      matchDetails: {
        matchID: null,
        matchTeams: null,
        matchDate: null,
      },
    },
    seasons: [],
    matches: [],
    fixturesConfig: null,
    matchesLoaded: false,
  }),

  mounted() {
    this.fetchFixturesConfig();
  },

  methods: {
    updateMatch() {
      const selectedMatch = this.matches.filter(
        (element) => element.matchID == this.matchData.matchDetails.matchID
      );
      this.matchData.matchDetails.matchTeams = selectedMatch[0].matchTeams;
      this.matchData.matchDetails.matchDate = selectedMatch[0].matchDate;
      this.$emit("updated", this.matchData);
    },

    fetchFixturesConfig() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`, {
            headers: { Authorization: jwt },
          })
          .then((response) => {
            // console.log('fetchFixturesConfig: ', response);
            this.fixturesConfig = response.data.body;

            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem retrieving fixtures", e);
            this.$store.commit("completeLoading");
          });
      });
    },

    setSeasons(team) {
      // console.log('seasons for which team?', team)
      this.matchData.teamID = team;

      for (const team of this.fixturesConfig) {
        // console.log("team in fixtureConfig", team);
        if (team.teamID === this.matchData.teamID) {
          this.seasons = team.seasons;
        }
      }
    },

    getFixtures() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(
            `${process.env.VUE_APP_BASEURL}/fixtures/opta?clientMatches=true&showCustom=${this.displayCustomFixtures}&teamID=${this.matchData.teamID}&seasonID=${this.matchData.seasonID}&pageSize=100`,
            { headers: { Authorization: jwt } }
          )
          .then((response) => {
            // console.log("Fetch fixtures response: ", response);
            if (response.data.totalDataCount) {
              this.matches = response.data.body.map((match) => {
                if (match.teamData) {
                  return {
                    matchID: match.matchID,
                    matchLabel:
                      match.teamData[0].teamName +
                      " Vs. " +
                      match.teamData[1].teamName +
                      " - " +
                      moment(match.kickOffUTC).format(
                        "ddd, MMM Do YYYY, HH:mm"
                      ),
                    matchTeams:
                      match.teamData[0].teamName +
                      " Vs. " +
                      match.teamData[1].teamName,
                    matchDate: moment(match.kickOffUTC).format(
                      "Do MMM YYYY - HH:mm"
                    ),
                  };
                } else {
                  return {
                    matchLabel: "Deleted Fixture",
                  };
                }
              });
              console.log(this.matches);
              this.matchesLoaded = true;
            }
          })
          .catch((e) => {
            console.error("Problem retrieving fixtures", e);
          });
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.match-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  border: 0;
  background-color: transparent;
  margin: 0;
}

.item {
  padding-right: 16px;
  min-width: 180px;
  width: 20%;

  &--match {
    padding-right: 0;
    width: 60%;
  }
}
</style>

