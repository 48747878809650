<template>
  <section class="matchday-takeovers">
    <h1 class="matchday-takeovers__heading">Upcoming matchday takeovers</h1>
    <p class="matchday-takeovers__description">Your fixtures are scheduled from within the fixture manager.  Only imported fixtures are currently supported for matchday takeovers.</p>

    <p class="notice">The New matchday takeover feature is in beta and not used across the whole GC platform.  For matchday takeovers on the web continue to use the existing homepage takeover functionality.</p>

    <div  v-if="takeovers.length">
      <div class="video__list-item" v-for="(takeover, index) in takeovers" :key="index">
        <div class="video__list-item--info">

          <h2 class="video__list-item-heading subheading">{{takeover.attributes.matchData.teamData[0].teamName}} v {{takeover.attributes.matchData.teamData[1].teamName}}</h2>

          <p class="video__list-item-details"><b>ON:</b> {{takeover.attributes.startAt | moment('DD/MM/YY @ kk:mm')}} | <b>OFF:</b> {{takeover.attributes.finishAt | moment('DD/MM/YY @ kk:mm')}}</p>

          <small class="video__list-item-type">{{takeover.id}}</small>

          <small class="video__list-item-error" v-if="clashDetected(index)">This takeover will not become active until the previous takeover has ended.</small>

        </div>
        <div class="crests">
          <img :src="`${imageapi}/${takeover.attributes.matchData.teamData[0].crestData.crestDefaultKey}`" />
          <img :src="`${imageapi}/${takeover.attributes.matchData.teamData[1].crestData.crestDefaultKey}`" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios"

export default {
  name: "MatchdayTakeoverV2",

  data: () => ({
    api: `${process.env.VUE_APP_MATCHDAYTAKEOVERAPI}/matchdaytakeover`,
    imageapi: `${process.env.VUE_APP_IMAGEURL}`,
    takeovers: []
  }),

  mounted() {
    this.getTakeovers()
  },

  methods: {
    getTakeovers() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          this.takeovers = response.data.data
          // console.log('takesovers', response.data.data)
        })
        .catch(e => {
          console.error("Problem getting takeovers", e);
        });
      })
    },
    clashDetected(key) {
      if (key > 0) {
        if (this.takeovers[key-1].attributes.finishAt >= this.takeovers[key].attributes.startAt) {
        return true
        } else {
          return false
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.matchday-takeovers {
    position: relative;
    margin: 16px 0 48px;
    padding: 32px 32px;
    background-color: #fff;
    border: 2px solid #DFE3E9;
    border-radius: 4px;

    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      width: 100%;
      left: 0;
      height: 1px;
      background-color: #DFE3E9;
    }

    &__heading {
      color: $purple;
      font-size: 24px;
      font-weight: 700;
    }

    &__description {
      margin: 6px 0 12px;
      padding: 0;
      color: #2b2b2b;
      font-size: 13px;
      font-weight: 300;
    }

    &__list {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &__link {
      display: inline-block;
      margin: 3px 0;
      padding: 3px 6px;
      color: #fff;
      font-size: 13px;
      background-color: $purple;
      border-radius: 4px;
      text-decoration: none;
      transition: background-color 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

      &:hover {
        background-color: rgba($purple, 0.7);
      }
    }

    &__illustration {
      position: absolute;
      top: -17px;
      right: 10px;
      width: 200px;
    }
  }

  //Lazy
  .video__list-item {
    justify-content: space-between;
  }

  .video__list-item-details {
    margin-bottom: 4px;
  }

  .video__list-item-error {
    background: $errorRed;
    color: white;
    border: 1px solid $errorRed;
    width: auto;
    padding: 2px 6px;
    border-radius: 6px;
    margin-left: 8px;
  }

  .crests {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    img {
      height: 60px;
      width: auto;
    }
  }

</style>