<template>
    <v-dialog
        v-model="dialog"
        width="600"
    >
        <section class="release-notes">

            <div class="release-notes__header">
                <v-card-title class="release-notes__heading">Release Notes</v-card-title>
                <v-icon
                    @click="$emit('closeReleaseNotes')"
                    class="release-notes__close-button"
                >close</v-icon>

                <div class="release-notes__tab-wrapper">
                    <div
                        @click="activeTab = 'cmsUpdates'"
                        :class="['release-notes__tab', { 'release-notes__tab--active': activeTab == 'cmsUpdates' }]"
                    >CMS Updates</div>
                    <div
                        @click="activeTab = 'tipsFeatures'"
                        :class="['release-notes__tab', { 'release-notes__tab--active': activeTab == 'tipsFeatures' }]"
                    >Features & Tips</div>
                </div>

                <div
                    v-show="activeTab == 'tipsFeatures'"
                    class="release-notes__tab-wrapper release-notes__tab-wrapper--sub-tabs"
                >
                    <div
                        @click="activeSubTab = 'features'"
                        :class="['release-notes__sub-tab', { 'release-notes__sub-tab--active': activeSubTab == 'features' }]"
                    >Features</div>
                    <div
                        @click="activeSubTab = 'tips'"
                        :class="['release-notes__sub-tab', { 'release-notes__sub-tab--active': activeSubTab == 'tips' }]"
                    >Tips</div>
                </div>
            </div>

            <!-- FIRST TAB -->
            <version-history
                v-show="activeTab == 'cmsUpdates'"
                @newVersion="$emit('newVersion')"
            />

            <!-- SECOND TAB -->
            <release-articles
                v-show="activeTab == 'tipsFeatures' && activeSubTab == 'features'"
                :category="'features'"
            />
            <release-articles
                v-show="activeTab == 'tipsFeatures' && activeSubTab == 'tips'"
                :category="'tips'"
            />
            <!-- <p v-show="activeTab == 'tipsFeatures'" class="coming-soon">Coming soon</p> -->

        </section>
    </v-dialog>
</template>

<script>
import VersionHistory from "@/components/ReleaseNotes/VersionHistory.vue"
import ReleaseArticles from "@/components/ReleaseNotes/ReleaseArticles.vue"

export default {
    name: "ReleaseNotesPopup",

    components: {
        VersionHistory,
        ReleaseArticles
    },

    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },

    data: () => ({
        activeTab: "cmsUpdates",
        activeSubTab: "features"
    })
}
</script>

<style scoped lang="scss">
.coming-soon {
    padding: 32px;
}

.release-notes {
    height: 600px;
    position: relative;
    background-color: white;
    border-radius: 4px;
    overflow: auto;

    &__header {
        padding: 16px;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
    }

    &__close-button {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    &__heading {
        padding: 0 0 16px;
        font-size: 16px;
        font-weight: 500;
        justify-content: center;
    }

    &__tab-wrapper {
        display: flex;
        justify-content: center;

        &--sub-tabs {
            padding: 12px 0 10px;
        }
    }

    &__tab {
        width: 50%;
        padding: 12px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: white;
        font-weight: 500;
        background-color: rgba(103, 36, 124, 0.3);
        transition: 0.2s;

        &:first-child {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }

        &:last-child {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }

        &--active {
            background-color: #67247C;
        }
    }

    &__sub-tab {
        width: 80px;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        font-size: 12px;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: -9px;
            left: 0;
            right: 0;
            height: 2px;
            background: rgba(103, 36, 124, 0.3);
            transition: 0.2s;
        }

        &--active {
            &:after {
                content: '';
                position: absolute;
                bottom: -10px;
                height: 4px;
                background: #67247C;
                border-radius: 10px;
            }
        }
    }
}
</style>
