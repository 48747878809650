<template>
  <section class="self-help">
    <h2 class="self-help__heading">{{ title }}</h2>
    <p class="self-help__description">Here are our top guides for this section</p>

    <ul v-if="support[location]" class="self-help__list">
      <li v-for="(item, index) in support[location]" :key="index">
        <a class="self-help__link" :href="item.url" target="_blank">{{ item.title }}</a>
      </li>
    </ul>

    <img class="self-help__illustration" :src="require('../../assets/illustrations/self-help-07.png')" />
  </section>
</template>

<script>
export default {
  name: "SelfHelp",

  props: {
    title: {
      type: String,
      default: "Need help?"
    },
    location: {
      type: String,
      validator: function (value) {
        // The value must match one of these strings
        return ["dashboard", "app", "sponsors", "advertising", "overlays"].indexOf(value) !== -1
      }
    }
  },

  data:() => ({
    support: {
      dashboard: [
        {
          title: "Getting Started on Gamechanger",
          url: "https://urbanzoo.freshdesk.com/a/solutions/articles/80000966056"
        },
        {
          title: "What are the Issue Priority Levels?",
          url: "https://urbanzoo.freshdesk.com/a/solutions/articles/80000254606"
        },
        {
          title: "How Do I turn On The Homepage Takeover?",
          url: "https://urbanzoo.freshdesk.com/a/solutions/articles/80000310038"
        },
      ],
      app: [
        {
          title: "Social Media Links Manager",
          url: "https://urbanzoo.freshdesk.com/a/solutions/articles/80000653332"
        },
        {
          title: "Ecommerce Links Manager",
          url: "https://urbanzoo.freshdesk.com/a/solutions/articles/80000408616"
        },
        {
          title: "Ticketing Links Manager",
          url: "https://urbanzoo.freshdesk.com/a/solutions/articles/80000408633"
        }
      ],
      sponsors: [
        {
          title: "How Do I Manage My Footer Sponsors?",
          url: "https://urbanzoo.freshdesk.com/a/solutions/articles/80000282101"
        }
      ],
      advertising: [
        {
          title: "How to use the Advertising section",
          url: "https://urbanzoo.freshdesk.com/a/solutions/articles/80000803939"
        }
      ],
      overlays: [
        {
          title: "Overlay Promos User Guide",
          url: "https://urbanzoo.freshdesk.com/a/solutions/articles/80000491659"
        }
      ],
    }
  })
}
</script>

<style scoped lang="scss">
  .self-help {
    position: relative;
    margin: 16px 0 48px;
    padding: 32px 250px 32px 32px;
    background-color: #fff;
    border: 2px solid #DFE3E9;
    border-radius: 4px;

    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      width: 100%;
      left: 0;
      height: 1px;
      background-color: #DFE3E9;
    }

    &__heading {
      color: $purple;
      font-size: 24px;
      font-weight: 700;
    }

    &__description {
      margin: 6px 0 12px;
      padding: 0;
      color: #2b2b2b;
      font-size: 13px;
      font-weight: 300;
    }

    &__list {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &__link {
      display: inline-block;
      margin: 3px 0;
      padding: 3px 6px;
      color: #fff;
      font-size: 13px;
      background-color: $purple;
      border-radius: 4px;
      text-decoration: none;
      transition: background-color 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

      &:hover {
        background-color: rgba($purple, 0.7);
      }
    }

    &__illustration {
      position: absolute;
      top: -17px;
      right: 10px;
      width: 200px;
    }
  }
</style>
