<template>
  <div class="list-stat">
    <h4 class="list-stat__title">{{ title }}</h4>
    <ol class="list-stat__list">
      <li v-for="(value, index) in values" :key="index" class="list-stat__item">
        <a :href="`https://${siteDomain}${value.pathname}`" target="_blank" class="list-stat__path">{{ value.pathname }}</a>
        <span class="list-stat__value">{{ value.total }}</span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "TopPagesListStat",

  props: {
    title: {
      type: String,
      required: true
    },
    values: {
      type: Array,
      required: true
    }
  },

  data:() => ({
    siteDomain: process.env.VUE_APP_SITE_DOMAIN,
  })
}
</script>

<style scoped lang="scss">
  .list-stat {
    padding: 16px;
    color: #fff;
    background: linear-gradient(45deg, #24002F, #67247c);

    &__title {
      font-size: 14px;
      font-weight: 300;
    }

    &__list {
      padding: 0;
      list-style: none;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 8px 0 0;
      padding: 2px 4px;
      font-size: 12px;
      font-weight: 300;
      background: rgba(0, 0, 0, 0.2);
    }

    &__path {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &__value {
      margin: 0 0 0 16px;
      font-weight: 500;
    }

  }
</style>
