<template>
  <v-dialog   
    v-model="isPickerOpen"
    width="580"
    lazy 
    class="fixture-details">

    <template v-slot:activator="{ on, attrs }">
      <div class="input-container" >
        <div class="input">
          <template v-if="matchData">
            <template v-if="matchData.matchDetails">
              <p class="input-text input-teams">{{ matchData.matchDetails.matchTeams }}</p>
              <p class="input-text input-date">{{ matchData.matchDetails.matchDate }}</p>
              <p class="input-text input-ids">{{ matchData.teamID }} | {{ matchData.seasonID }} | {{ matchData.matchDetails.matchID }}</p>
            </template>
          </template>
          <p class="empty-message" v-else><slot></slot></p>
        </div>
        
        <div v-bind="attrs" @on="on" @click="isPickerOpen = true" class="icon-container">
          <v-icon class="search-icon">search</v-icon>
        </div>
      </div>
    </template>

    <slot slot="activator"></slot>
    <v-card v-if="isPickerOpen" class="card">
      <v-icon @click="isPickerOpen = false" class="close-icon">close</v-icon>
      <v-card-title primary-title class="headline grey lighten-2">Match Picker</v-card-title>
      <v-card-text>
        <match-picker-v2 @updated="fetchFixture($event)" :displayCustomFixtures="false" :restrictSubTeams="restrictSubTeams"/>
      </v-card-text>
      <v-btn
        class="button"
        color="accent"
        depressed
        :disabled="!allowAdd"
        @click="validate()"
      >
        Add fixture
      </v-btn>
    </v-card>
    
  </v-dialog>
</template>

<script>
import MatchPickerV2 from "@/components/Library/MatchPickerV2"

export default {
  name: "FixtureDetails",

  components: {
    MatchPickerV2
  },

  props: {
    fetchedMatchData: {
      type: Object,
      required: false,
    },
    restrictSubTeams: {
      type: Boolean,
      required: false
    }
  },

  data: () => ({
    isPickerOpen: false,
    allowAdd: false,
    matchData: null,
  }),

  created() {
    if (this.fetchedMatchData) {
      this.matchData = JSON.parse(JSON.stringify(this.fetchedMatchData))
    }
  },

  watch: {
    fetchedMatchData() {
      this.matchData = JSON.parse(JSON.stringify(this.fetchedMatchData))
    }
  },

  methods: {
    fetchFixture(payload) {
      this.matchData = payload
      this.allowAdd = true;
    },

    validate() {
      this.isPickerOpen = false
      this.$emit("fetchMatchData", this.matchData)
    },
  }
}
</script>

<style scoped lang='scss'>

.input-container {
  position: relative;
}

.input {
  min-height: 56px;
  width: 100%;
  border: 1px solid rgb(223, 227, 233);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 4px 10px;
  background: rgba(#f8f8f8, 0.1);
  border-top: 4px solid #67247c;
}

.input-text {
  max-width: calc(100% - 60px);
  font-size: 10px;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.input-teams {
  color: #67247c;
  font-weight: bold;
  font-size: 20px;
}

.empty-message {
  color: grey;
  font-size: 14px;
  font-style: italic;
  margin-top: 8px;
}

.input-date {
  background-color: peachpuff;
  padding: 2px 6px;
  border-radius: 6px;
  display: inline-block;
}

.input-ids {
  color: #7b6b80;
  opacity: 0.8;
}

.icon-container {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 8px;
  border-radius: 50%;
  border: 2px solid grey;
  font-size: 0;
  cursor: pointer;
  padding: 8px;
}

.search-icon {
  font-size: 22px;
}
.card {
  position: relative;
  padding-bottom: 6px;
}

.close-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  font-size: 16px;
}

.button {
  display: block;
  margin: 6px auto;
}
</style>
